import React from "react";
import "./ChangeMDP.scss";
import { useDispatch, useSelector } from 'react-redux';
import { changeNewPassInputs } from '../../actions/action';
import { sendNewPassForm } from '../../actions/api';
import closedEye from '../../assets/Images/yeux.png'
import openedEye from '../../assets/Images/oeil.png'

function ChangeMDP() {

  const { password, confirmPass } =  useSelector((state) => state.reducer.webSite.changePassword);

  const showPassword1 = () => {
    const passwordElement = document.querySelector("#show1");
    const eyeElement = document.querySelector("#eye1");
    if (passwordElement.type === "password") {
      eyeElement.src = openedEye
      passwordElement.type = "text";
    } else {
      eyeElement.src = closedEye
      passwordElement.type = "password";
    }
  } 

  const showPassword2 = () => {
    const passwordElement = document.querySelector("#show2");
    const eyeElement = document.querySelector("#eye2");
    if (passwordElement.type === "password") {
      eyeElement.src = openedEye
      passwordElement.type = "text";
    } else {
      eyeElement.src = closedEye
      passwordElement.type = "password";
    }
  } 

  const handleChange = (e) => {
    const wrongMDPElement = document.querySelector(
      "form > div:nth-child(3) > p"
    );
    if (e.target.name === "confirmPass") {
      if (e.target.value != password) {
        wrongMDPElement.className = "warning";
        wrongMDPElement.textContent = "Le mot de passe ne correspont pas.";
        return;
      }
    }
    wrongMDPElement.className = "hidden";
    dispatch(changeNewPassInputs(e.target.name, e.target.value));
  };

  const dispatch = useDispatch();

  const handleSubmit = (e) => {
    e.preventDefault();
    if (confirmPass === password) {
      dispatch(sendNewPassForm());
    }
  };

  return (
    <div className="ChangeMDP">

      <form onSubmit={handleSubmit}>
        <h2>Changer son mot de passe</h2>
        <div className="inputLabel checkValidation">
        <label htmlFor="password">Mot de passe*</label>
            <p>{"Votre mot de passe doit contenir au moins: une majuscule, une minuscule, un chiffre, et un caractère spécial (longueur comprise entre 8 et 32 caractères)."}</p>
            <div className='passwordWraper'>
            <input id='show1' className='password' onChange={handleChange} type="password" name='password' pattern="^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*?_=+-]).{8,32}$" autoComplete={"new-password"}  required/>
            <img id='eye1' onClick={showPassword1} className='eyes' src={closedEye} alt="" />
            </div>
        </div>

        <div className="inputLabel checkValidation">
        <label htmlFor="confirmPass">Confirmer le mot de passe*</label>
            <div className='passwordWraper'>
            <input id='show2' className='password' onChange={handleChange} type="password" name='confirmPass' pattern="^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*?_=+-]).{8,32}$" required/>
            <img id='eye2' onClick={showPassword2} className='eyes' src={closedEye} alt="" />
            </div>
          <p className="hidden"></p>
        </div>
        <p>{"(* Champs obligatoires)"}</p>
        <input className="submitButton" type="submit" value="Confirmer" />
      </form>
    </div>
  );
}
export default ChangeMDP;
