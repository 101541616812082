import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setNav } from '../../actions/action';
import NavMobile from '../NavMobile/NavMobile';
import HeaderImg from '../../assets/Images/danceriser-logo2.svg';

import './Header.scss';
import { Link } from 'react-router-dom';


function Header() {
    const navOpen = useSelector((state) => state.reducer.webSite.navOpen)
    const dispatch = useDispatch();
    
    const handleClick = () => {
        dispatch(setNav());
    }


  return(
    <div className={navOpen ? 'Header active' : 'Header'}>
        <NavMobile closeNavFunction={handleClick} />
        <div onClick={handleClick} className="burgerButton">
            <div className="line"></div>
        </div>

        <h1>
            <Link to='/'>
               Dance Riser
            </Link>
        </h1>

        <Link to='/'>
            <img className='HeaderImg' src={HeaderImg} alt="logo de DanceRiser" />
        </Link>
    </div>
 )
}
export default Header;