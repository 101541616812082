import React from 'react';
import { Link } from 'react-router-dom';
import './UserMailValidation.scss';


function UserMailValidation() {

  return(
    <div className='UserMailValidation'>
       <h3>{"Votre inscription a bien été prise en compte."}</h3>
       <p className='mailInfo'><span>{"Un mail vous a été envoyé. "}</span><br/>{"Pour valider votre inscription, veuillez cliquer sur le lien présent dans le mail. Connectez-vous ensuite avec votre adresse mail et votre mot de passe."}</p>
       <p>{"Nous vous remercions d'utiliser les services Dance Riser."}</p>
       <Link to='/login'>
       <button>{"CONNEXION"}</button>
       </Link>
    </div>
 )
}
export default UserMailValidation;