import React, { useEffect } from 'react';
import './Move.scss';
import VideoCard from '../../../../VideoCard/VideoCard';
import { useDispatch, useSelector } from 'react-redux';
import { callOneMove } from '../../../../../actions/api';
import { useParams } from 'react-router-dom';
import Loading from '../../../../Loading/Loading';
import { setAllLoadingFalse } from '../../../../../actions/action';

function Move() {

  const { slugMove, idMove} = useParams();
  const dispatch = useDispatch();
  const move = useSelector((state) => state.reducer.movePage);
  const loading = useSelector((state) => state.reducer.loading.loadingMoves)

  useEffect(() => {
    dispatch(setAllLoadingFalse())
    setTimeout(() => {
      dispatch(callOneMove(slugMove, idMove));
    }, 100);
  }, []);

  useEffect(() => {
  }, [move]);


  return(
    <div className='Move'>
    {loading && <Loading/>}
    {!loading && 
      <section className='moveList'>
      <h2>{move.name}</h2>
      <h3>niveau du mouvement: {move.level.name}</h3>

      <div className="description">
            <p>{move.description}</p>
      </div>
        
        <div className="videosContainer">
          {
            move.videos.map((video) => (
              <VideoCard
                key={video.description}
                description={video.description}
                link={video.link}
              />
            ))
          }
        </div>

      </section>
    }
    </div>
 )
}
export default Move;