import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
   changeSchoolFormInput,
   changeCourseFormInput,
   changeLocationFormInput,
   changeOpenToFormInput,
   changeLevelFormInput,
   addSchoolLocation,
   DeleteSchoolLocation,
   changePDPSchool,
} from '../../actions/action';
import { sendSubscribeSchoolForm } from '../../actions/api';
import './SchoolMembership.scss';


function SchoolMembership() {

   const dispatch = useDispatch();
   const school = useSelector((state) => state.reducer.schoolMemberShip)
   const locations = useSelector((state) => state.reducer.schoolMemberShip.locations);
   const user = useSelector((state) => state.reducer.user);

   useEffect(() => {
         if (localStorage.getItem("roles")) {
            const role = localStorage.getItem("roles");
            const schoolListNotParsed = localStorage.getItem("schoolList");
            const schoolList = JSON.parse(schoolListNotParsed)
            if (role !== 'ROLE_MEMBER' || schoolList[0]) {
               window.location.href = '/login';
            }
         }
   }, [])

   const handleSubmit = (e) => {
      e.preventDefault();

      const openTo = [];
      const levelOfSchool = [];
      const lessonType = [];
      const locationss = [];

      // cree un nouveau tableau pour l'envoie des donnees
      if (school.openTo.babies === true){
         openTo.push('Babies')
      }
      if (school.openTo.children === true){
         openTo.push('Children')
      }
      if (school.openTo.adult === true){
         openTo.push('Adults')
      }
      // cree un nouveau tableau pour l'envoie des donnees
      if (school.levelOfSchool.beginner === true){
         levelOfSchool.push(61)
      }
      if (school.levelOfSchool.intermediate === true){
         levelOfSchool.push(62)
      }
      if (school.levelOfSchool.advanced === true){
         levelOfSchool.push(63)
      }
      if (school.levelOfSchool.professionnal === true){
         levelOfSchool.push(64)
      }
      // cree un nouveau tableau pour l'envoie des donnees
      if (school.courseTypes.public === true){
         lessonType.push('Public')
      }
      if (school.courseTypes.private === true){
         lessonType.push('Private')
      }

      locations.forEach((location) => {
         locationss.push({
            address: location.address,
            postalCode: location.postalCode,
            city: location.city,
            country: location.country,
         })
      })

      dispatch(sendSubscribeSchoolForm(
         school.email,
         school.schoolName,
         user.id,
         school.avatar,
         school.schoolDescription,
         school.telNumber,
         school.comment,
         locationss,
         openTo,
         levelOfSchool,
         lessonType,
      ));
   };

   const handleChange = (e) => {
      dispatch(changeSchoolFormInput(e.target.name, e.target.value));
   };

   const handleChangeCourseType = (e) => {
      dispatch(changeCourseFormInput(e.target.name, e.target.checked));
   };

   const handleChangeOpenTo = (e) => {
      dispatch(changeOpenToFormInput(e.target.name, e.target.checked));
   };

   const handleChangeLevel = (e) => {
      dispatch(changeLevelFormInput(e.target.name, e.target.checked));
   };

   const handleAddLocation = () => {
      if (locations.length < 10) {
         const myLoc = locations.find((location) => location.active === true)
         dispatch(addSchoolLocation(myLoc.index + 1));
      }
   };

   const handleChangeLocationText = (e) => {
      
      const object = locations[e.target.id];
      dispatch(changeLocationFormInput(e.target.name, e.target.value));
      switch(e.target.name){
         case 'city':{
            object.city = e.target.value;
         }
         break;
         case 'postalCode':{
            object.postalCode = e.target.value;
         }
         break;
         case 'address':{
            object.address = e.target.value;
         }
         break;
         case 'country':{
            object.country = e.target.value;
         }
         break;
      }
   };

   const handleDeleteLocation = (e) => {
      const object = locations[e.target.id];

      const newArray = [];
      locations.forEach((location) => {
         if (location != object) {
            newArray.push(location)
         }
      })
      dispatch(DeleteSchoolLocation(newArray));
   };

   const fileChange = (e) => {
      const file = e.target.files[0]
      const warningElement = document.querySelector("div.inputLabel.file > p");
      if(file.size > 1048576 ){
        const warning = document.querySelector('.warning')
        if (warning){
          return;
        }
        warningElement.className = 'warning'
        warningElement.textContent = "La taille de votre image est trop grande, celle-ci n'a pas été ajoutée, veuillez sélectionner une autre image. Taille maximum = 1Mo"
        return ;
      }
      warningElement.className='hidden'

      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.addEventListener('load', () => {
        dispatch(changePDPSchool(reader.result))
      });
    };


  return(
    <div className='SchoolMembership'>
       <form onSubmit={handleSubmit}>
          <h2>{"Demande d'adhésion"}</h2>
          
         <p>{"Voici le formulaire vous permettant de demander l'adhésion, de votre groupe (école/Crew) au site de Danse Riser."}</p>

         <p>{"Si votre demande est acceptée, votre compte utilisateur sera automatiquement transformé, en compte manager de votre groupe."}</p>
          
          <div className="inputLabel file">
            <img className='imgPdp' src={school.avatar} alt="photo de votre école" />
            <p className='hidden'></p>
            <input className='pdpInput' onChange={fileChange} type="file" name='pdp' />
          </div>

          <div className="inputLabel">
            <label htmlFor="schoolName">{"Nom de l'école*"}</label>
            <input onChange={handleChange} type="text" name='schoolName' required/>
          </div>

          <div className="inputLabel">
            <label htmlFor="email">{"Email de l'école/responsable*"}</label>
            <input onChange={handleChange} type="email" name='email' required/>
          </div>

          <div className="inputLabel">
            <label htmlFor="telNumber">Numéro de téléphone</label>
            <input onChange={handleChange} type="tel" pattern='^[0-9]{1-12}[+]' name='telNumber' />
          </div>

         {locations.map((location, index) => (
            location.active && 
            <div key={index + 1} className="inputLabel">
               {location.xMark && (
                  <div className='line'></div>
               )}
               <label htmlFor={'address'}>{"Lieu d'entraînement n°"+ (index + 1)}</label>
               <label>Adresse</label>
               <div className="locationDiv">
                  <input onChange={handleChangeLocationText} id={index} type="text" name={'address'} value={location.address} />
                  {location.xMark && (
                     <i onClick={handleDeleteLocation} id={index} className="fa-solid fa-xmark"></i>
                  )}
               </div>
               <div className="inputLabel">
                  <label htmlFor={'city'}>Ville</label>
                  <input onChange={handleChangeLocationText} id={index} type="text" name={'city'} value={location.city} />
               </div>
               <div className="inputLabel">
                  <label htmlFor={'postalCode'}>Code postal</label>
                  <input onChange={handleChangeLocationText} id={index} type="number" name={'postalCode'} value={location.postalCode} />
               </div>
               <div className="inputLabel">
                  <label htmlFor={'country'}>Pays</label>
                  <input onChange={handleChangeLocationText} id={index} type="text" name={'country'} value={location.country} />
               </div>
            </div>
         ))}

         <div className="inputLabel button">
            <p onClick={handleAddLocation}>{"Ajouter un lieu d'entraînement"}</p>
         </div>

          <div className="inputLabel">
            <label htmlFor="schoolDescription">{"Description de l'école*"}</label>
            <textarea onChange={handleChange} type="text" name='schoolDescription' required/>
          </div>

          <div className="inputLabel">
               <p>Type de cours</p>
               <div className="inputCheckbox">
                  <input onChange={handleChangeCourseType} type="checkbox" name="private"/>
                  <label htmlFor="private">Privés</label>
               </div>
               <div className="inputCheckbox">
                  <input onChange={handleChangeCourseType} type="checkbox" name="public"/>
                  <label htmlFor="public">Publics</label>
               </div>
          </div>

          <div className="inputLabel">
               <p>Ouvert aux</p>
               <div className="inputCheckbox">
                  <input onChange={handleChangeOpenTo} type="checkbox" name="babies"/>
                  <label htmlFor="babies">Babies</label>
               </div>
               <div className="inputCheckbox">
                  <input onChange={handleChangeOpenTo} type="checkbox" name="children"/>
                  <label htmlFor="children">Enfants</label>
               </div>
               <div className="inputCheckbox">
                  <input onChange={handleChangeOpenTo} type="checkbox" name="adult"/>
                  <label htmlFor="adult">Adultes</label>
               </div>
          </div>

          <div className="inputLabel">
               <p>{"Niveau de l'école/ crew"}</p>
               <div className="inputCheckbox">
                  <input onChange={handleChangeLevel} type="checkbox" name="beginner"/>
                  <label htmlFor="beginner">Débutant</label>
               </div>
               <div className="inputCheckbox">
                  <input onChange={handleChangeLevel} type="checkbox" name="intermediate"/>
                  <label htmlFor="intermediate">Intermédiaire</label>
               </div>
               <div className="inputCheckbox">
                  <input onChange={handleChangeLevel} type="checkbox" name="advanced"/>
                  <label htmlFor="advanced">Avancé</label>
               </div>
               <div className="inputCheckbox">
                  <input onChange={handleChangeLevel} type="checkbox" name="professionnal"/>
                  <label htmlFor="professionnal">Professionnel</label>
               </div>
               <div className="inputLabel">
                  <label className='messageLabel' htmlFor="comment">Message*</label>
                  <textarea onChange={handleChange} name="comment" className='comment' required></textarea>
              </div>
          </div>
          <p className='required'>{"(* Champs obligatoires)"}</p>
          <input className='submitButton' type="submit" value="Inscription" />
       </form>
    </div>
 )
}
export default SchoolMembership;