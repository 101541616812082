import React from 'react';
import './School.scss';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom'
import {
   callOneSchool,
   SendAdhesionSchool
} from '../../../actions/api';
import {
   changeSchoolSubmitMessage,
   changeSchoolSubmitCheckbox,
   removeSchoolForm,
   AddSchoolForm,
   setAllLoadingFalse,
} from '../../../actions/action';
import { useEffect } from 'react';
import Loading from '../../Loading/Loading';
import ImageCard from '../../ImageCard/ImageCard';

function School() {

   const dispatch = useDispatch()
   const { slugSchool, schoolId } = useParams();
   const user = useSelector((state) => state.reducer.user)
   const school = useSelector((state) => state.reducer.schoolPage);
   const loading = useSelector((state) => state.reducer.loading.loadingSchool);
   const contactSchool = useSelector((state) => state.reducer.webSite.contactSchool);

   useEffect(() => {
      dispatch(setAllLoadingFalse())
      dispatch(callOneSchool(slugSchool, schoolId));
      dispatch(AddSchoolForm());

      user.schoolList && (
         user.schoolList.forEach((school) => {
            if (school.school.slug == slugSchool) {
               dispatch(removeSchoolForm());
            }
         })
   )
   }, [])

   useEffect(() => {
      if (school === undefined) {
         window.location.href = '/error404';
      }
   }, [school])

   const handleSubmit = (e) => {
      e.preventDefault();
      dispatch(SendAdhesionSchool({
         comment: contactSchool.message,
         user_id: user.id,
         school_id: school.id,
      }))
   };
   const handleChange = (e) => {
      dispatch(changeSchoolSubmitMessage(e.target.value));
   };
   const handleChangeCheckbox = () => {
      dispatch(changeSchoolSubmitCheckbox());

   };


if (loading) {
   return(
      <Loading/>
   )
}else{  
  return(
      <div className='School'>
         <ImageCard
               title={school.name}
               lien={school.image}
         />

         <div className="TitleText centred">
            <h2>Numéro de Téléphone</h2>
            <p>{school.phone}</p>
         </div>

         <div className="TitleText centred">
            <h2>Email</h2>
            <p>{school.email}</p>
         </div>

         <section className='schoolSectionList'>
               <div className="TitleText centred section">
                  <h2>Type de cours:</h2>
                  <ul>
                  {school.lessonType.map((course) => (
                     <li key={course}>{course}</li>
                  ))}
                  </ul>
               </div>

               <div className="TitleText centred section">
                  <h2>Niveaux acceptés:</h2>
                  <ul>
                     {school.level.map((level) => (
                     <li key={level.name}>{level.name}</li>
                     ))}
                  </ul>
               </div>

               <div className="TitleText centred section">
                  <h2>Ouverts aux:</h2>
                  <ul>
                     {school.openTo.map((openTo) => (
                     <li key={openTo}>{openTo}</li>
                     ))}
                  </ul>
               </div>
         </section>

         <section className='schoolSectionDesc'>
               <div className="TitleText section">
                  <h2>Description</h2>
                  <p>{school.description}</p>
               </div>

               <div className="TitleText section">
                  <h2>Lieu</h2>
                  <p className='location'>{school.locations[0].city}</p>
               </div>
            {user.isLogged && contactSchool.active && user.role !== "ROLE_MANAGER" && user.role !== "ROLE_ADMIN" && (
               <form onSubmit={handleSubmit}>
               <div className="navLine"></div>
               <div className="inputLabel">
                     <label htmlFor="content">Message*</label>
                     <textarea onChange={handleChange} name="content" required></textarea>
               </div>
               <div className="inputLabel checkbox">
                     <input onChange={handleChangeCheckbox} type='checkbox' name="schoolCheckbox" required />
                     <label htmlFor="schoolCheckbox">{'Je confirme l\'envoie de mes informations personnels à l\'école (nom, prénom, email)* '}</label>
               </div>
               <p className='required'>{"(* Champs obligatoires)"}</p>
               <input className='submitButton' type="submit" value="Rejoindre l'école" />
               </form>
            )}
         </section>
      </div>
 )
}
}
export default School;