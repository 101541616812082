import {
    SET_ALL_LOADING_FALSE,
    CHANGE_FORM_INPUT,
    CHANGE_INPUT,
    SET_EVENT_FILTER,
    SET_SCHOOL_FILTER,
    SET_NAV,
    SUBMIT_CONTACT_FORM,
    CHANGE_SCHOOL_FORM_INPUT,
    CHANGE_SCHOOL_LOCATION_FORM_INPUT,
    CHANGE_SCHOOL_OPENTO_FORM_INPUT,
    CHANGE_SCHOOL_LEVEL_FORM_INPUT,
    CHANGE_SCHOOL_COURSE_FORM_INPUT,
    ADD_SCHOOL_LOCATION,
    DELETE_SCHOOL_LOCATION,
    MODIFY_SETTINGS_PROFILE,
    CLOSE_SETTINGS_PROFILE,
    SET_CHOREO_FILTER,
    CHANGE_CHORE_LEVEL_SEARCH,
    CHANGE_USER_SETTINGS_INPUT,
    SET_MOVES_FILTER,
    CHANGE_MOVES_LEVEL_SEARCH,
    CLOSE_NAV,
    CHANGE_SCHOOL_SUBMIT_MESSAGE,
    CHANGE_SCHOOL_SUBMIT_CHECKBOX,
    CHANGE_PDP,
    CHANGE_PDP_SETTINGS,
    CHANGE_PDP_SCHOOL,
    REMOVE_SCHOOL_FORM,
    ADD_SCHOOL_FORM,
    LOGOUT,
    CHANGE_EVENTS_SEARCH_INPUT,
    EVENTS_SEARCH_SUBMIT,
    EVENTS_RESET_SUBMIT,
    CHANGE_SCHOOL_SEARCH_INPUT,
    SCHOOL_SEARCH_SUBMIT,
    SCHOOL_RESET_SUBMIT,
    CHANGE_CHOREO_SEARCH_INPUT,
    CHOREO_FILTRES,
    CHANGE_MOVES_SEARCH_INPUT,
    MOVES_FILTRES,
    CHANGE_NEW_PASS_INPUTS,
    SET_SCHOOL_INPUT_SESSION_STORAGE,
    SET_EVENT_INPUT_SESSION_STORAGE,
    SET_CHORE_INPUT_SESSION_STORAGE,
    SET_MOVE_INPUT_SESSION_STORAGE,
} from "../actions/action";


import {
    MAKE_UNDEFINED,
    SAVE_SCHOOLS,
    SAVE_EVENTS,
    SAVE_ONE_EVENTS,
    SET_LOADING,
    SAVE_ONE_SCHOOL,
    SAVE_HOME,
    SAVE_CHOREOGRAPHYS_STYLES_LIST,
    SAVE_CHOREOGRAPHYS_LIST,
    SAVE_ONE_CHOREGRAPHY,
    SAVE_MOVES_STYLES_LIST,
    SAVE_MOVES_CATEGORIES_LIST,
    SAVE_MOVES_LIST,
    SAVE_ONE_MOVE,
    SAVE_LOGIN,
    SAVE_LOGIN_DETAIL,
} from "../actions/api";

export const initialState = {
    webSite:{
        navOpen:false,
        eventFilter:false,
        schoolFilter:false,
        choreographyFilter:false,
        moveFilter:false,
        eventSearch: {
            name: '',
            postalCode: '',
            city: '',
        },
        schoolSearch: {
            name: '',
            postalCode: '',
            city: '',
        },
        moveSearch: {
            name: '',
            // beginner:false,
            // intermediate:false,
            // advanced:false,
            // professionnal:false,
        },
        choreSearch: {
            name: '',
            // beginner:false,
            // intermediate:false,
            // advanced:false,
            // professionnal:false,
        },
        detailsProfilChange: false,
        detailsProfilChangeInputs: {
        lastName:'',
        firstName:'',
        avatar:'',
        blaze: '',
        email: '',
        agendaLink: '',
        },
        resetPassword: {
            isSubmit: false,
            email: '',
           
        },
        contactSchool: {
            message: '',
            userInformations: false,
            active: true,
        },
        changePassword:{
            password: '',
            confirmPassword: '',
        }
    },
    loading:{
        loadingHomeEvents: true,
        loadingEvents: true,
        loadingEvent: true,
        loadingSchools: true,
        loadingSchool: true,
        loadingChoreStyleList: true,
        loadingChoreStylePage: true,
        loadingChore: true,
        loadingMovesStyleList: true,
        loadingMovesStylePage: true,
        loadingMovesCategoriesPage: true,
        loadingMoves: true,
    },
    user:{
        isLogged: false,
        activated: true,
        id: undefined,
        avatar: '',
        email: '',
        password: '',
        confirmPass:'',
        blaze: '',
        firstName:'',
        lastName:'',
        role:'',
        schoolList:[],
        level:'',
        token: '',
        agendaLink: '',
    },
    homeEventList: [],
    eventList: [],
    eventListFilter: [],
    eventPage: {},
    schoolList: [],
    schoolListFilter: [],
    schoolPage: [],
    choreStyleList: [],
    choreStylePage: [],
    choreStylePageFilter: [],
    chorePage: {},
    moveStyleList: {},
    moveStylePage: {},
    movesListPage: {},
    movesListPageFilter: {},
    movePage: {},
    contact: {
        email: '',
        firstLastName: '',
        subject: '',
        content: '',
        submit: false
    },
    schoolMemberShip: {
        avatar:'',
        schoolName: '',
        email: '',
        telNumber: '',
        locations: [
            {
                address:'',
                postalCode: '',
                city: '',
                country: '',
                active:true,
                xMark: false,
            },
        ],
        schoolDescription: '',
        courseTypes: {
            private: false,
            public: false,
        },
        openTo: {
            babies: false,
            children: false,
            adult: false,
        },
        levelOfSchool: {
            beginner:false,
            intermediate:false,
            advanced:false,
            professionnal:false,
        },
        comment:'',
    },
};

const reducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case SET_ALL_LOADING_FALSE:
        return{
            ...state,
            loading: {
                ...state.loading,
                loadingHomeEvents: true,
                loadingEvents: true,
                loadingEvent: true,
                loadingSchools: true,
                loadingSchool: true,
                loadingChoreStyleList: true,
                loadingChoreStylePage: true,
                loadingChore: true,
                loadingMovesStyleList: true,
                loadingMovesStylePage: true,
                loadingMovesCategoriesPage: true,
                loadingMoves: true,
            }
        }
      case SET_NAV:
          return{
            ...state,
            webSite: {
                ...state.webSite,
                navOpen: !state.webSite.navOpen,
            }
          };
      case CLOSE_NAV:
          return{
            ...state,
            webSite: {
                ...state.webSite,
                navOpen: false,
            }
          };
      case LOGOUT:
        localStorage.clear();
          return{
            ...state,
            user: {
                ...state.user,
                isLogged: false,
                id: undefined,
                avatar: '',
                email: '',
                password: '',
                confirmPass: '',
                blaze: '',
                firstName: '',
                lastName: '',
                role: '',
                schoolList: undefined,
                level: '',
                token: '',
            }
          };
      case CHANGE_INPUT:
          return{
            ...state,
            user: {
                ...state.user,
                [action.name]: action.value,
            }
          };
      case CHANGE_PDP:
          return{
            ...state,
            user: {
                ...state.user,
                avatar: action.value,
            }
          }
      case CHANGE_PDP_SETTINGS:
          return{
            ...state,
            webSite: {
                ...state.webSite,
                detailsProfilChangeInputs: {
                    ...state.webSite.detailsProfilChangeInputs,
                    avatar: action.value
                }
            }
          }
      case CHANGE_PDP_SCHOOL:
          return{
            ...state,
            schoolMemberShip: {
                ...state.schoolMemberShip,
                avatar: action.value
            }
          }
      case CHANGE_FORM_INPUT:
          return{
            ...state,
            contact: {
                ...state.contact,
                [action.name]: action.value,
            }
          };
      case SUBMIT_CONTACT_FORM:
          return{
            ...state,
            contact: {
                ...state.contact,
                submit: true,
            }
          };
      case SET_EVENT_FILTER:
          return{
          ...state,
          webSite: {
              ...state.webSite,
              eventFilter: !state.webSite.eventFilter,
           }
          };
      case SET_SCHOOL_FILTER:
          return{
          ...state,
          webSite: {
              ...state.webSite,
              schoolFilter: !state.webSite.schoolFilter,
           }
          };
      case CHANGE_SCHOOL_FORM_INPUT:
          return{
          ...state,
          schoolMemberShip: {
              ...state.schoolMemberShip,
              [action.name]: action.value,
           }
          };
      case CHANGE_SCHOOL_LOCATION_FORM_INPUT:
          return{
          ...state,
          schoolMemberShip: {
              ...state.schoolMemberShip,
              location: {
                ...state.schoolMemberShip.location,
                [action.name]: action.value,
              }
           }
          };
      case CHANGE_SCHOOL_OPENTO_FORM_INPUT:
          return{
          ...state,
          schoolMemberShip: {
              ...state.schoolMemberShip,
              openTo: {
                ...state.schoolMemberShip.openTo,
                [action.name]: action.value,

              }
           }
          };
      case CHANGE_SCHOOL_LEVEL_FORM_INPUT:
          return{
          ...state,
          schoolMemberShip: {
              ...state.schoolMemberShip,
              levelOfSchool: {
                ...state.schoolMemberShip.levelOfSchool,
                    [action.name]: action.value,
              }
           }
          };
      case CHANGE_SCHOOL_COURSE_FORM_INPUT:
          return{
          ...state,
          schoolMemberShip: {
              ...state.schoolMemberShip,
              courseTypes: {
                ...state.schoolMemberShip.courseTypes,
                    [action.name]: action.value,
              }
           }
          };
      case ADD_SCHOOL_LOCATION:
        return{
        ...state,
        schoolMemberShip: {
            ...state.schoolMemberShip,
            locations: [
                ...state.schoolMemberShip.locations,
                {
                    address:'',
                    postalCode: '',
                    city: '',
                    country: '',
                    active:true,
                    xMark: true,
                },
            ]
         }
        };
      case DELETE_SCHOOL_LOCATION:
        return{
        ...state,
        schoolMemberShip: {
            ...state.schoolMemberShip,
            locations: action.array
         }
        };
        case MODIFY_SETTINGS_PROFILE:
          return{
          ...state,
          webSite: {
              ...state.webSite,
              detailsProfilChange: true,
           }
          };
        case CLOSE_SETTINGS_PROFILE:
            return{
            ...state,
            webSite: {
                ...state.webSite,
                detailsProfilChange: false,
                detailsProfilChangeInputs: {
                ...state.webSite.detailsProfilChangeInputs,
                lastName: state.user.lastName,
                firstName: state.user.firstName,
                avatar: state.user.avatar,
                blaze: state.user.blaze,
                email: state.user.email,
                password: state.user.password,
                confirmPass: state.user.confirmPass,
                agendaLink: state.user.agendaLink,
                }
            }
        };
      case SET_CHOREO_FILTER:
          return{
          ...state,
          webSite: {
              ...state.webSite,
              choreographyFilter: !state.webSite.choreographyFilter,
           }
          };
      case CHANGE_CHORE_LEVEL_SEARCH:
          return{
          ...state,
          webSite: {
              ...state.webSite,
              choreographySearch: {
                ...state.webSite.choreographySearch,
                    [action.name]: action.value,
              }
           }
          };
      case CHANGE_USER_SETTINGS_INPUT:
          if (action.name === "agendaLink") {
              let cleanLink = action.value.replace("<iframe src=\"", "")
              let regex = / style="border.*$/
              cleanLink = cleanLink.replace(regex,"")
              action.value = cleanLink
          }
          return{
            ...state,
            webSite: {
                ...state.webSite,
                detailsProfilChangeInputs: {
                    ...state.webSite.detailsProfilChangeInputs,
                    [action.name]: action.value,
                },
            }
          };
      case SET_MOVES_FILTER:
          return{
          ...state,
          webSite: {
              ...state.webSite,
              moveFilter: !state.webSite.moveFilter,
           }
          };
      case CHANGE_MOVES_LEVEL_SEARCH:
          return{
          ...state,
          webSite: {
              ...state.webSite,
              moveSearch: {
                ...state.webSite.moveSearch,
                [action.name]: action.value,
              }
           }
          };
        case CHANGE_NEW_PASS_INPUTS :
            return {
                ...state,
                webSite: {
                    ...state.webSite,
                    changePassword:{
                        ...state.webSite.changePassword,
                        [action.name] : action.value
                    }
                }
            }
        case CHANGE_SCHOOL_SUBMIT_MESSAGE :
            return{
                ...state,
                webSite: {
                    ...state.webSite,
                    contactSchool: {
                    ...state.webSite.contactSchool,
                    message: action.value,
                    }
                }
        }
        case CHANGE_SCHOOL_SUBMIT_CHECKBOX :
            return{
                ...state,
                webSite: {
                    ...state.webSite,
                    contactSchool: {
                    ...state.webSite.contactSchool,
                    userInformations: !state.webSite.contactSchool.userInformations,
                    }
                }
        }
        case REMOVE_SCHOOL_FORM :
            return{
                ...state,
                webSite: {
                    ...state.webSite,
                    contactSchool: {
                    ...state.webSite.contactSchool,
                    active: false,
                    }
                }
            }
        case ADD_SCHOOL_FORM :
            return{
                ...state,
                webSite: {
                    ...state.webSite,
                    contactSchool: {
                    ...state.webSite.contactSchool,
                    active: true,
                    }
                }
            }
        case CHANGE_EVENTS_SEARCH_INPUT: 
            return{
                ...state,
                webSite:{
                    ...state.webSite,
                    eventSearch:{
                        ...state.webSite.eventSearch,
                        [action.name] : action.value
                    }
                }
            }
        case EVENTS_SEARCH_SUBMIT:
            action.data = state.eventList;
            if (state.webSite.eventSearch.name !== ''){
                action.data = {
                    dates: state.eventList.dates,
                    events: action.data.events.filter((event) => {
                        return event.name.toLowerCase().startsWith(state.webSite.eventSearch.name.toLowerCase());
                    }),
                }
            }
            if (state.webSite.eventSearch.city !== ''){
                action.data = {
                    dates: state.eventList.dates,
                    events: action.data.events.filter((event) => {
                        return event.location.city.toLowerCase().startsWith(state.webSite.eventSearch.city.toLowerCase());
                    }),
                }
            }
            if (state.webSite.eventSearch.postalCode !== ''){
                action.data = {
                    dates: state.eventList.dates,
                    events: action.data.events.filter((event) => {
                        return event.location.postalCode.toLowerCase().startsWith(state.webSite.eventSearch.postalCode.toLowerCase());
                    }),
                }
            }

            if (state.webSite.eventSearch.name === ''
            &&  state.webSite.eventSearch.city === ''
            &&  state.webSite.eventSearch.postalCode === ''
            ) {
                action.data.events = state.eventList.events;
                action.data = {
                    dates: state.eventList.dates,
                    events: state.eventList.events,
                }
            }

            sessionStorage.setItem("eventSearch", JSON.stringify(
                {
                    name: state.webSite.eventSearch.name,
                    city: state.webSite.eventSearch.city,
                    postalCode: state.webSite.eventSearch.postalCode,
                }
            ));
            return{
                ...state,
                eventListFilter: action.data
            }
        case EVENTS_RESET_SUBMIT:
            return{
                ...state,
                eventListFilter: state.eventList,
                webSite: {
                    ...state.webSite,
                    eventSearch: {
                        ...state.webSite.eventSearch,
                        name: '',
                        city: '',
                        postalCode: '',
                    }
                }
            }
        case SET_EVENT_INPUT_SESSION_STORAGE:
            return {
                ...state,
                eventListFilter: state.eventList,
                webSite: {
                    ...state.webSite,
                    eventSearch: {
                        ...state.webSite.eventSearch,
                        name: action.name,
                        city: action.city,
                        postalCode: action.postalCode,
                    }
                }
            }
        case SET_CHORE_INPUT_SESSION_STORAGE:
        if (action.name != "") {
            action.data = state.choreStylePage.filter((choreo) => {
                return choreo.name.toLowerCase().startsWith(action.name.toLowerCase());
            })
        }else{
            action.data = state.choreStylePage;
        }
            return {
                ...state,
                choreStylePageFilter: action.data,
                webSite: {
                    ...state.webSite,
                    choreSearch: {
                        ...state.webSite.choreSearch,
                        name: action.name,
                    }
                }
            }
        case SET_MOVE_INPUT_SESSION_STORAGE:
            if (action.name != "") {
                action.data = state.movesListPageFilter.filter((move) => {
                    return move.name.toLowerCase().startsWith(action.name.toLowerCase());
                })
            }else{
                action.data = state.movesListPage;
            }
                return {
                    ...state,
                    movesListPageFilter: action.data,
                    webSite: {
                        ...state.webSite,
                        moveSearch: {
                            ...state.webSite.moveSearch,
                            name: action.name,
                        }
                    }
                }
        case CHANGE_SCHOOL_SEARCH_INPUT:
            return{
                ...state,
                webSite:{
                    ...state.webSite,
                    schoolSearch:{
                        ...state.webSite.schoolSearch,
                        [action.name] : action.value
                    }
                }
            }

        
        case SCHOOL_SEARCH_SUBMIT:
            action.data = state.schoolList
            if (state.webSite.schoolSearch.name !== ''){
                action.data = action.data.filter((school) => {
                    return school.name.toLowerCase().startsWith(state.webSite.schoolSearch.name.toLowerCase());
                })
            }
            if (state.webSite.schoolSearch.city !== ''){
                action.data = action.data.filter((school) => {
                    return school.locations[0].city.toLowerCase().startsWith(state.webSite.schoolSearch.city.toLowerCase());
                })
            }
            if (state.webSite.schoolSearch.postalCode !== ''){
                action.data = action.data.filter((school) => {
                    return school.locations[0].postalCode.toLowerCase().startsWith(state.webSite.schoolSearch.postalCode.toLowerCase());
                })
            }
            if (state.webSite.schoolSearch.name === ''
            &&  state.webSite.schoolSearch.city === ''
            &&  state.webSite.schoolSearch.postalCode === ''
            ) {
                action.data = state.schoolList;
            }
            
            sessionStorage.setItem("schoolSearch", JSON.stringify(
                {
                    name: state.webSite.schoolSearch.name,
                    city: state.webSite.schoolSearch.city,
                    postalCode: state.webSite.schoolSearch.postalCode,
                }
            ));  
            return{
                ...state,
                schoolListFilter: action.data
            }

        case SCHOOL_RESET_SUBMIT:
            return{
                ...state,
                schoolListFilter: state.schoolList,
                webSite: {
                    ...state.webSite,
                    schoolSearch: {
                        ...state.webSite.schoolSearch,
                        name: '',
                        city: '',
                        postalCode: '',
                    }
                }
            }
        case SET_SCHOOL_INPUT_SESSION_STORAGE:
            return {
                ...state,
                schoolListFilter: state.schoolList,
                webSite: {
                    ...state.webSite,
                    schoolSearch: {
                        ...state.webSite.schoolSearch,
                        name: action.name,
                        city: action.city,
                        postalCode: action.postalCode,
                    }
                }
            }
        case CHANGE_CHOREO_SEARCH_INPUT:
            return{
                ...state,
                webSite: {
                    ...state.webSite,
                    choreSearch: {
                        ...state.webSite.choreSearch,
                        name: action.value,
                    }
                }
            }
        case CHOREO_FILTRES:
            action.data = state.choreStylePage;

            if (state.webSite.choreSearch.name !== ''){
                action.data = action.data.filter((choreo) => {
                    return choreo.name.toLowerCase().startsWith(state.webSite.choreSearch.name.toLowerCase());
                })
            }
            if (state.webSite.choreSearch.name === '') {
                action.data = state.choreStylePage;
            }

            sessionStorage.setItem("choreSearch", JSON.stringify(
                {
                    name: state.webSite.choreSearch.name,
                }
            ));  
            return{
                ...state,
                choreStylePageFilter: action.data
            }

        case CHANGE_MOVES_SEARCH_INPUT:
            return{
                ...state,
                webSite: {
                    ...state.webSite,
                    moveSearch: {
                        ...state.webSite.moveSearch,
                        [action.name]: action.value,
                    }
                }
            }
        
        case MOVES_FILTRES:
            action.data = state.movesListPage;

            if (state.webSite.moveSearch.name !== ''){
                action.data = action.data.filter((move) => {
                    return move.name.toLowerCase().startsWith(state.webSite.moveSearch.name.toLowerCase());
                })
            }
            if (state.webSite.moveSearch.name === '') {
                action.data = state.movesListPage;
            }
            sessionStorage.setItem("moveSearch", JSON.stringify(
                {
                    name: state.webSite.moveSearch.name,
                }
            )); 
            return{
                ...state,
                movesListPageFilter: action.data
            }
        // OOO                                       ||
        // OOO                                       ||
        // OOO                                       ||
        // OOO                                       ||
        // OOO                                       ||
        // OOO                                       ||
        // OOO                                       ||
        // OOO                                       ||
        // OOO                                       ||
        // OOO                                       ||
        // OOO                                       ||
        // OOO                                       ||
        // OOO                                       ||
        // OOO                                       ||
        // OOO                                       ||
        // API                                  ____________ 
        // API                                   __________
        // API                                    ________ 
        // API                                     ______ 
        // API                                      ____ 
        // API                                       __ 
        case MAKE_UNDEFINED:
            return{
                ...state,
                [action.name]: undefined,
            }
        case SAVE_SCHOOLS:
            return{
                ...state,
                schoolList: action.data,
                schoolListFilter: action.data,
            }
        case SAVE_ONE_SCHOOL:
            return{
                ...state,
                schoolPage: action.data,
            }
        case SAVE_EVENTS:
            return{
                ...state,
                eventList: action.data,
                eventListFilter: action.data,
            }
        case SAVE_ONE_EVENTS:
            return{
                ...state,
                eventPage: action.data
            }
        case SET_LOADING:
            return{
                ...state,
                loading: {
                    ...state.loading,
                    [action.loading]: action.bool,
                }
            }
        case SAVE_HOME:
            return{
                ...state,
                homeEventList: action.data
            }
        case SAVE_CHOREOGRAPHYS_STYLES_LIST:
            return{
            ...state,
            choreStyleList: action.data
            }
        case SAVE_CHOREOGRAPHYS_LIST:
            return{
                ...state,
                choreStylePage: action.data,
                choreStylePageFilter: action.data
            }
        case SAVE_ONE_CHOREGRAPHY:
            return{
                ...state,
                chorePage: action.data
            }
            case SAVE_MOVES_STYLES_LIST:
            return{
                ...state,
                moveStyleList: action.data
            }
        case SAVE_MOVES_CATEGORIES_LIST:
            return{
                ...state,
                moveStylePage: action.data
            }
        case SAVE_MOVES_LIST:
            return{
                ...state,
                movesListPage: action.data,
                movesListPageFilter: action.data
            }
        case SAVE_ONE_MOVE:
            return{
                ...state,
                movePage: action.data
            }
        case SAVE_LOGIN:
            return{
                ...state,
                user: {
                    ...state.user,
                    isLogged: true,
                    email: action.email,
                    id: action.id,
                    blaze: action.blaze,
                    firstName: action.firstName,
                    lastName: action.lastName,
                    role: action.role,
                    schoolList: action.schoolList,
                    activated: action.activated,
                    level: action.level,
                    token: action.token,
                    avatar: action.avatar,
                    agendaLink: action.agendaLink,
                }
            }
        case SAVE_LOGIN_DETAIL:
            return{
                ...state,
                webSite: {
                    ...state.webSite,
                    detailsProfilChangeInputs:{
                        ...state.webSite.detailsProfilChangeInputs,
                        email: action.email,
                        blaze: action.blaze,
                        firstName: action.firstName,
                        lastName: action.lastName,
                        avatar: action.avatar,
                        agendaLink: action.agendaLink,
                }
                }
            }

    default:
      return state;
  }
};

export default reducer;
