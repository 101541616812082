import React from 'react';
import './Loading.scss';


function Loading() {
  return(
    <div className='Loading'>
    <div className="loader">
  <div className="bar1">{"D"}</div>
  <div className="bar2">{"A"}</div>
  <div className="bar3">{"N"}</div>
  <div className="bar4">{"C"}</div>
  <div className="bar5">{"E"}</div>
  <div className="bar6">{""}</div>
  <div className="bar7">{""}</div>
  <div className="bar8">{"R"}</div>
  <div className="bar9">{"I"}</div>
  <div className="bar10">{"S"}</div>
  <div className="bar11">{"E"}</div>
  <div className="bar12">{"R"}</div>
</div>
      <p className='loadingText'>{" Votre page est en cours de chargement, veuillez patienter ..."}</p>
    </div>
 )
}
export default Loading;