import React, { useEffect } from "react";
import { useSelector, useDispatch } from 'react-redux';
import "./Agenda.scss";
import { callSchools } from '../../actions/api';
import Loading from '../Loading/Loading';

function Agenda() {
  const dispatch = useDispatch()
  const agendaLink = useSelector((state) => state.reducer.user.agendaLink)
  const schools = useSelector((state) => state.reducer.user.schoolList)
  const loading = useSelector((state) => state.reducer.loading.loadingSchools);

  useEffect(() => {
      if(!localStorage.getItem("token")) {
        window.location.href = '/';
      }else{
        dispatch(callSchools())
      }
  }, [])


  if (loading) {
    return(
       <Loading/>
    )
 }else{ 
  return (
    <div className="Agenda">
{!agendaLink && (
  <>
    <h2>Mon Agenda :</h2>
    <p >{"Vous n'avez pas encore d'agenda personnel"}</p> 
  </>
)}
{agendaLink && 
    <>
      <h2>Mon Agenda :</h2>
      <iframe
        title="Agenda"
        src={agendaLink}
        frameBorder="0"
        scrolling="no"
        allow="fullscreen"
        loading="lazy"
      />
    </>
} 
{!schools && <p > {"Pour accéder à l'agenda de votre groupe, vous devez être membre de celui-ci."}</p> }
{schools && 
<>
  {schools.map((school, id) =>
    <div key={id} className="schoolAgenda">
      <h2>{school.school.name} :</h2>
      {!school.school.agendaLink && <p>{"Il n'y a pas encore d'agenda mis en place pour votre groupe"}</p> }
      {school.school.agendaLink && 
        <iframe
        title="Agenda"
        src={school.school.agendaLink}
        frameBorder="0"
        scrolling="no"
        allow="fullscreen"
        loading="lazy"
        />
      }
    </div>
  )}
</>
} 

    </div>
  );
}
}
export default Agenda;
