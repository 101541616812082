import React from 'react';
import './ErrorPage.scss';
import { Link } from 'react-router-dom';

function ErrorPage() {


  return(
    <div className='ErrorPage'>
       <h2>{"404"}</h2>
       <h3>{"We can't find that page"}</h3>
       <p>{"We're fairly sure that page used to be here, but seems to have gone missing. We do apologise on it's behalf"}</p>
       <Link to='/'>
       <button>{"HOME"}</button>
       </Link>
    </div>
 )
}
export default ErrorPage;