import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { callOneChoreography } from '../../../actions/api';
import VideoCard from '../../VideoCard/VideoCard';
import Loading from '../../Loading/Loading';
import './Choreography.scss';
import { setAllLoadingFalse } from '../../../actions/action';


function Choreography() {
    const dispatch = useDispatch();
    const { slugChore, idChore } = useParams();
    const chore = useSelector((state) => state.reducer.chorePage);
    const loading = useSelector((state) => state.reducer.loading.loadingChore);

    useEffect(() => {
        dispatch(setAllLoadingFalse())
    }, []);
    
    useEffect(() => {
        setTimeout(() => {
            if(localStorage.getItem("token")) {
                dispatch(callOneChoreography(slugChore, idChore))
            }else{
                window.location.href = '/login';
            }
        }, 100);
    }, [])

    
   
  return(
        <div className='Choreography'>
        {loading && <Loading/>}
    {!loading && 
        <section className='styleList'>
            <h2>{chore.name}</h2>

            <div className="informationTextPart">
                <p className='descriptionPart'><span>Description de la chorégraphie</span>:<br/>{chore.description}</p>
                <p className="levelPart"><span>Niveau</span>: Débutant</p>
            </div>

            {chore.choreographyParts.map((parts, index) => (
                
                <section key={parts.name} className="sectionPart">
                    
                        <h3 className='titlePart'>{'Partie '+ (index+ 1)}: </h3>
                        {parts.videos.map((videos) => (
                            <VideoCard
                                key={videos.link}
                                title={parts.name}
                                description={videos.description}
                                link={videos.link}
                            />
                        ))}
                </section>

            ))}

           
        </section>}
    </div>
 )
}

export default Choreography;