import React from 'react';
import { Link } from 'react-router-dom';
import './Footer.scss';
import TitleImg from '../../assets/Images/dance-riser.png';
import { useSelector } from 'react-redux';

function Footer() {

  const { role, schoolList } = useSelector((state) =>  state.reducer.user)


  return(
    <div className='Footer'>
      <img src={TitleImg} className="title" alt="" />
       <Link to='/CGU' >{"Conditions générales d'utilisations"}</Link>
       <Link to='/privacy' >{"Politique de Confidentialité"}</Link>
       <Link to='/sitemap' >{"plan du site"}</Link>
       {role === 'ROLE_MEMBER' && !schoolList[0] && (
         <Link to='/schools/add' >{"Ajouter mon école"}</Link>
       )} 
       <Link to='/contact' >{"Contact"}</Link>
    </div>
 )
}
export default Footer;