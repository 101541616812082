import React, { useEffect, } from 'react';
import { useParams } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import ImageCard from '../../ImageCard/ImageCard';
// import { findEvent } from '../../../selectors/selector';
import { callOneEvent } from '../../../actions/api';
import './Event.scss';
import Loading from '../../Loading/Loading';
import { setAllLoadingFalse } from '../../../actions/action';


function Event() {
   const dispatch = useDispatch()
   const { slugEvent, idEvent } = useParams();
   const loading = useSelector((state) => state.reducer.loading.loadingEvent);
   const event = useSelector((state) => state.reducer.eventPage);
   useEffect(() => {
      dispatch(setAllLoadingFalse())
      dispatch(callOneEvent(slugEvent, idEvent))
   }, [])

if (loading) {
   return(
      <Loading/>
   )
}else{   
  return(
    <div className='Event'>
         <ImageCard
            className="card"
            title={event.events.name}
            lien={event.events.image}
         />

         <div className="TitleText">
            <h2>Numéro de Téléphone du responsable</h2>
            <p>{event.events.phone}</p>
         </div>

         <div className="TitleText">
            <h2>Email du responsable</h2>
            <p>{event.events.email}</p>
         </div>

         <section>
            <div className="TitleText section">
               <h2>Description</h2>
               <p>{event.events.description}</p>
            </div>

            <div className="TitleText section">
               <h2>Date et heure</h2>
               <p>le {event.dates[event.events.id]}</p>
            </div>

            <div className="TitleText section">
               <h2>Lieu</h2>
               <p>
                  adresse: {event.events.location.address}<br/>
                  ville: {event.events.location.city}<br/>
                  code postale: {event.events.location.postalCode}
               </p>
            </div>
         </section>
    </div>
)
   }
}
export default Event;