/* eslint-disable no-undef */
import React, { useEffect } from 'react';
import './App.scss';
import { useSelector, useDispatch } from 'react-redux';
import { Route, Routes, useLocation } from 'react-router-dom';
import ErrorPage from '../ErrorPage/ErrorPage';
import Home from '../Home/Home';
import Schools from '../Schools/Schools';
import School from '../Schools/School/School';
import SchoolMembership from '../SchoolMembership/SchoolMembership';
import Events from '../Events/Events';
import Header from '../Header/Header';
import Footer from '../Footer/Footer';
import MovesList from '../MovesList/MovesList';
import MovesCategories from '../MovesList/MovesCategories/MovesCategories';
import Moves from '../MovesList/MovesCategories/Moves/Moves';
import Move from '../MovesList/MovesCategories/Moves/Move/Move';
import Subscribe from '../Subscribe/Subscribe';
import Login from '../Login/Login';
import Contact from '../Contact/Contact';
import CGU from '../CGU/CGU';
import SiteMap from '../SiteMap/SiteMap';
import Privacy from '../Privacy/Privacy';
import Profil from '../Profil/Profil';
import ProfilDetails from '../Profil/ProfilDetails/ProfilDetails';
import Event from '../Events/Event/Event';
import ChoreographyStyles from '../ChoreographyStyles/ChoreographyStyles';
import ChoreographyList from '../ChoreographyStyles/ChoreographyList/ChoreographyList';
import Choreography from '../ChoreographyStyles/Choreography/Choreography';
import {
  closeNav,
  logout,
} from '../../actions/action';
import Agenda from '../Agenda/Agenda';
import Loading from '../Loading/Loading';
import ValidationPage from '../ValidationPage/ValidationPage';
import {
  saveLogin,
  saveLoginDetail
} from '../../actions/api';
import ChangeMDP from '../ChangeMDP/ChangeMDP';
import HelpAgenda from '../HelpAgenda/HelpAgenda';
import UserMailValidation from '../UserMailValidation/UserMailValidation';

function App() {

  const dispatch = useDispatch();
  const navOpen = useSelector((state) => state.reducer.webSite.navOpen)

  const location = useLocation();

  useEffect(() => {
      window.scrollTo(0, 0);
  },[location]);


  useEffect(() => {
    if(localStorage.getItem("token")) {
      if (Date.now() > localStorage.getItem("temps")) {
        localStorage.clear();
        dispatch(logout());
      }else{
        let schoolList = localStorage.getItem("schoolList");
        let blaze = localStorage.getItem("blaze");
        let activated = localStorage.getItem("activated");
        // const levelNotParse = localStorage.getItem("level")
        let level = localStorage.getItem("level");
        let agenda = localStorage.getItem("agendaLink");
        if(schoolList === 'undefined'){
          schoolList = undefined;
        }
        else{
          const school = localStorage.getItem("schoolList");
          schoolList = JSON.parse(school);
        }
        if(blaze === 'null'){
          blaze = undefined;
        }
        if(activated === 'true'){
          activated = true;
        }else if(activated === 'false'){
          activated = false;
        }
        if(level === 'undefined'){
          level = undefined;
        }
        if(agenda === 'null'){
          agenda = '';
        }
        dispatch(saveLogin(
          localStorage.getItem("email"),
          parseInt(localStorage.getItem("id")),
          blaze,
          localStorage.getItem("firstname"),
          localStorage.getItem("lastname"),
          localStorage.getItem("roles"),
          schoolList,
          activated,
          level,
          localStorage.getItem("token"),
          localStorage.getItem("avatar"),
          agenda,
        ));
        dispatch(saveLoginDetail(
          localStorage.getItem("email"),
          blaze,
          localStorage.getItem("firstname"),
          localStorage.getItem("lastname"),
          localStorage.getItem("avatar"),
          agenda,
        ));
      }
    }else{
      dispatch(logout());
    }
  }, [])

  const handleClick = (e) => {
    if (navOpen){
      if (!e.target.className.includes('NavMobile')) {
        dispatch(closeNav());
      }
    }
  };
  
  return(
    <div onClick={handleClick} className='App'>
       <Header />

       <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/user" element={<Profil />} />
        <Route path="/user/settings" element={<ProfilDetails />} />
        <Route path="/schools" element={<Schools />} />
        <Route path="/schools/:slugSchool/:schoolId" element={<School />} />
        <Route path="/schools/add" element={<SchoolMembership />} />
        <Route path="/subscribe" element={<Subscribe />} />
        <Route path="/login" element={<Login />} />
        <Route path="/events" element={<Events />} />
        <Route path="/events/:slugEvent/:idEvent" element={<Event />} />
        <Route path="/moves/styles" element={<MovesList />} />
        <Route path="/moves/styles/:slugCategories" element={<MovesCategories />} />
        <Route path="/moves/styles/categories/:slugMoves" element={<Moves/>} />
        <Route path="/moves/styles/categories/moves/:slugMove/:idMove" element={<Move/>} />
        <Route path="/choreography/styles" element={<ChoreographyStyles/>} />
        <Route path="/choreography/styles/:slugStyle" element={<ChoreographyList/>} />
        <Route path="/choreography/styles/:slugStyle/:slugChore/:idChore" element={<Choreography/>} />
        <Route path="/contact" element={<Contact/>} />
        <Route path="/CGU" element={<CGU/>} />
        <Route path="/agenda" element={<Agenda/>} />
        <Route path="/privacy" element={<Privacy/>} />
        <Route path="/sitemap" element={<SiteMap/>} />
        <Route path="/loading" element={<Loading/>} />
        <Route path="/validate" element={<ValidationPage/>} />
        <Route path="/help-agenda" element={<HelpAgenda/>} />
        <Route path="/security/changepassword" element={<ChangeMDP/>} />
        <Route path="*" element={<ErrorPage />} />
        <Route path="/mailValidation" element={<UserMailValidation/>}/>
      </Routes>
      
      <Footer/>
    </div>
 )
}
export default App;