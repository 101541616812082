import React from "react";
import "./SiteMap.scss";

function SiteMap() {
  return (
    <div className="SiteMap">
        <h2>Plans du site:</h2>
      <div className="SiteMapCard">
        <ul>
            <li>{"Home"}</li>
            <li>{"Schools-List"}</li>
                <ul>
                    <li>{"School-details"}</li>
                    <li>{"SchoolMemberShip"}</li>
                </ul>
            <li>{"Events-List"}</li>
                <ul>
                    <li>{"Event-details"}</li>
                </ul>
            <li>{"Chorégraphies-Styles"}</li>
                <ul>
                    <li>{"Chorégraphie-List"}</li>
                    <li>{"Chorégraphie-details"}</li>
                </ul>
            <li>{"Moves-Styles"}</li>
                <ul>
                    <li>{"Moves-Categories"}</li>
                    <li>{"Moves-List"}</li>
                    <li>{"Move-details"}</li>
                </ul>
            <li>{"My Profil"}</li>
                <ul>
                    <li>{"Profil-details"}</li>
                </ul>            
            <li>{"My School"}</li>
            <li>{"Back-Office"}</li>
            <li>{"Conditions générales d'utilisations"}</li>
            <li>{"Contact"}</li>
            <li>{"Error 404"}</li>
        </ul>
      </div>
    </div>
  );
}
export default SiteMap;
