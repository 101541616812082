export const MAKE_UNDEFINED = 'MAKE_UNDEFINED';
export const makeUndefined = (name) => ({
  type: MAKE_UNDEFINED,
  name: name,
});

// CALL API HOME
export const CALL_HOME = 'CALL_HOME';
export const callHome = () => ({
  type: CALL_HOME
});

export const SAVE_HOME = 'SAVE_HOME';
export const saveHome = (data) => ({
  type: SAVE_HOME,
  data: data,
});
// CALL API HOME

// -------------------------------------

// CALL API SCHOOL
export const CALL_SCHOOLS = 'CALL_SCHOOLS';
export const callSchools = () => ({
  type: CALL_SCHOOLS
});

export const CALL_ONE_SCHOOL = 'CALL_ONE_SCHOOL';
export const callOneSchool = (slug, id) => ({
  type: CALL_ONE_SCHOOL,
  slug: slug,
  id: id,
});

export const SAVE_SCHOOLS = 'SAVE_SCHOOLS';
export const saveSchools = (data) => ({
  type: SAVE_SCHOOLS,
  data: data,
});

export const SAVE_ONE_SCHOOL = 'SAVE_ONE_SCHOOL';
export const saveOneSchool = (data) => ({
  type: SAVE_ONE_SCHOOL,
  data: data,
});

export const SEND_ADHESION_SCHOOLS = 'SEND_ADHESION_SCHOOLS';
export const SendAdhesionSchool = (data) => ({
  type: SEND_ADHESION_SCHOOLS,
  data: data,
});

// CALL API SCHOOL

// -------------------------------------

// CALL API EVENT
export const CALL_EVENTS = 'CALL_EVENTS';
export const callEvents = () => ({
  type: CALL_EVENTS
});

export const CALL_ONE_EVENT = 'CALL_ONE_EVENT';
export const callOneEvent = (slug, id) => ({
  type: CALL_ONE_EVENT,
  slug: slug,
  id: id,
});

export const SAVE_EVENTS = 'SAVE_EVENTS';
export const saveEvents = (data) => ({
  type: SAVE_EVENTS,
  data: data,
});

export const SAVE_ONE_EVENTS = 'SAVE_ONE_EVENTS';
export const saveOneEvents = (data) => ({
  type: SAVE_ONE_EVENTS,
  data: data,
});

export const SET_LOADING = 'SET_LOADING';
export const setLoading = (loading ,bool) => ({
  type: SET_LOADING,
  loading: loading,
  bool: bool,
});
// CALL API EVENT

// -------------------------------------

// CALL API CONTACT
export const SEND_CONTACT_FORM = 'SEND_CONTACT_FORM';
export const sendContactForm = () => ({
  type: SEND_CONTACT_FORM
});
// CALL API CONTACT

// -------------------------------------

// CALL API MON PROFIL
export const SEND_SETTINGS_FORM = 'SEND_SETTINGS_FORM';
export const sendSettingsForm = () => ({
  type: SEND_SETTINGS_FORM
});
// CALL API MON PROFIL

// -------------------------------------

// CALLS API MOVES
export const CALL_MOVES_STYLES_LIST = 'CALL_MOVES_STYLES_LIST';
export const callMovesStylesList = () => ({
  type: CALL_MOVES_STYLES_LIST
});

export const SAVE_MOVES_STYLES_LIST = 'SAVE_MOVES_STYLES_LIST';
export const saveMovesStylesList = (data) => ({
  type: SAVE_MOVES_STYLES_LIST,
  data: data,
});

export const CALL_MOVES_CATEGORIES_LIST = 'CALL_MOVES_CATEGORIES_LIST';
export const callMovesCategoriesList = (slug) => ({
  type: CALL_MOVES_CATEGORIES_LIST,
  slug: slug,
});

export const SAVE_MOVES_CATEGORIES_LIST = 'SAVE_MOVES_CATEGORIES_LIST';
export const saveMovesCategoriesList = (data) => ({
  type: SAVE_MOVES_CATEGORIES_LIST,
  data: data,
});

export const CALL_MOVES_LIST = 'CALL_MOVES_LIST';
export const callMovesList = (slug) => ({
  type: CALL_MOVES_LIST,
  slug: slug,
});

export const CALL_ONE_MOVE = 'CALL_ONE_MOVE';
export const callOneMove = (slug, id) => ({
  type: CALL_ONE_MOVE,
  slug: slug,
  id: id,
});

export const SAVE_MOVES_LIST = 'SAVE_MOVES_LIST';
export const saveMovesList = (data) => ({
  type: SAVE_MOVES_LIST,
  data: data,
});

export const SAVE_ONE_MOVE = 'SAVE_ONE_MOVE';
export const saveOneMove = (data) => ({
  type: SAVE_ONE_MOVE,
  data: data,
});
// CALLS API MOVES

// -------------------------------------

// CALLS API CHOREOGRAPHYS
export const CALL_CHOREOGRAPHYS_STYLES_LIST = 'CALL_CHOREOGRAPHYS_STYLES_LIST';
export const callChoreographysStylesList = () => ({
  type: CALL_CHOREOGRAPHYS_STYLES_LIST
});

export const SAVE_CHOREOGRAPHYS_STYLES_LIST = 'SAVE_CHOREOGRAPHYS_STYLES_LIST';
export const saveChoreographysStylesList = (data) => ({
  type: SAVE_CHOREOGRAPHYS_STYLES_LIST,
  data: data,
});

export const CALL_CHOREOGRAPHYS_LIST = 'CALL_CHOREOGRAPHYS_LIST';
export const callChoreographysList = (slug) => ({
  type: CALL_CHOREOGRAPHYS_LIST,
  slug: slug
});

export const CALL_ONE_CHOREOGRAPHY = 'CALL_ONE_CHOREOGRAPHY';
export const callOneChoreography = (slug, id) => ({
  type: CALL_ONE_CHOREOGRAPHY,
  slug: slug,
  id: id,
});

export const SAVE_CHOREOGRAPHYS_LIST = 'SAVE_CHOREOGRAPHYS_LIST';
export const saveChoreographysList = (data) => ({
  type: SAVE_CHOREOGRAPHYS_LIST,
  data: data,
});

export const SAVE_ONE_CHOREGRAPHY = 'SAVE_ONE_CHOREGRAPHY';
export const saveOneChoregraphy = (data) => ({
  type: SAVE_ONE_CHOREGRAPHY,
  data: data,
});
// CALLS API CHOREOGRAPHYS

// -------------------------------------

// CALL API LOGIN
export const SEND_LOGIN_FORM = 'SEND_LOGIN_FORM';
export const sendLoginForm = (data) => ({
  type: SEND_LOGIN_FORM,
  data: data
});

export const SAVE_LOGIN = 'SAVE_LOGIN';
export const saveLogin = (
    email,
    id,
    blaze,
    firstName,
    lastName,
    role,
    schoolList,
    activated,
    level,
    token,
    avatar,
    agendaLink,
) => ({
    type: SAVE_LOGIN,
    email: email,
    id: id,
    blaze: blaze,
    firstName: firstName,
    lastName: lastName,
    role: role,
    schoolList: schoolList,
    activated: activated,
    level: level,
    token: token,
    avatar: avatar,
    agendaLink: agendaLink,
});

export const SAVE_LOGIN_DETAIL = 'SAVE_LOGIN_DETAIL';
export const saveLoginDetail = (
    email,
    blaze,
    firstName,
    lastName,
    avatar,
    agendaLink,
) => ({
    type: SAVE_LOGIN_DETAIL,
    email: email,
    blaze: blaze,
    firstName: firstName,
    lastName: lastName,
    avatar: avatar,
    agendaLink: agendaLink,
});

export const SEND_DETAIL_PROFIL = 'SEND_DETAIL_PROFIL';
export const sendDetailProfil = (
    email,
    blaze,
    firstName,
    lastName,
    avatar,
    agendaLink,
) => ({
    type: SEND_DETAIL_PROFIL,
    email: email,
    blaze: blaze,
    firstName: firstName,
    lastName: lastName,
    avatar: avatar,
    agendaLink: agendaLink,
});
// CALL API LOGIN

// -------------------------------------

// CALL API SUBSCRIBE
export const SEND_SUBSCRIBE_FORM = 'SEND_SUBSCRIBE_FORM';
export const sendSubscribeForm = (
    email,
    password,
    blaze,
    firstname,
    lastname,
    avatar
) => ({
    type: SEND_SUBSCRIBE_FORM,
    email: email,
    password: password,
    blaze: blaze,
    firstname: firstname,
    lastname: lastname,
    avatar: avatar,
});
// CALL API SUBSCRIBE

// -------------------------------------

// CALL API SUBSCRIBE SCHOOL
export const SEND_SUBSCRIBE_SCHOOL_FORM = 'SEND_SUBSCRIBE_SCHOOL_FORM';
export const sendSubscribeSchoolForm = (
    email,
    name,
    user,
    image,
    description,
    phone,
    comment,
    locations,
    openTo,
    level,
    lessonType,
) => ({
    type: SEND_SUBSCRIBE_SCHOOL_FORM,
    email: email,
    name: name,
    user: user,
    image: image,
    description: description,
    phone: phone,
    comment: comment,
    locations: locations,
    openTo: openTo,
    level: level,
    lessonType: lessonType,
});
// CALL API SUBSCRIBE SCHOOL

// CHANGE PASSWORD
export const SEND_NEW_PASS_FORM = 'SEND_NEW_PASS_FORM';
export const sendNewPassForm = () => ({
  type: SEND_NEW_PASS_FORM,
});