import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import ImageCard from '../ImageCard/ImageCard';
import ImageBackground from '../../assets/Images/background.jpg';
import './Home.scss';
import { callHome } from '../../actions/api';
import { logout, setAllLoadingFalse } from '../../actions/action';
import Loading from '../Loading/Loading';


function Home() {

  const eventList = useSelector((state) => state.reducer.homeEventList);
  const loading = useSelector((state) => state.reducer.loading.loadingHomeEvents);
  const isLogged = useSelector((state) => state.reducer.user.isLogged);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(setAllLoadingFalse())
  }, []);
  
  useEffect(() => {
    dispatch(callHome());
  }, [])

  const handleClick = () => {
    dispatch(logout());
  }

  return(
    <div className='Home'>

       <section className='HomeBackground'>
         <img className='backgroundImg' src={ImageBackground} alt="" />
         <Link onClick={isLogged && handleClick} className='backgroundLink' to={'/login'}>
            <i className="fa-solid fa-user"></i>
            <p>{isLogged ? 'se déconnecter': 'se connecter'}</p>
         </Link>
         <p className='HomeText'>{'Bienvenue sur Dance Riser'}</p>
       </section>

       <section className='HomeAbout'>
         <h2>{"A propos du site"}</h2>
          <p className='avertissement'>
          {"Attention, le contenu de "}<span className='bold'>{"Dance Riser"}</span>{" est encore"} <span className='bold'>{"cours de construction"}</span>{", vous retrouverez"} <span className='bold'>{" régulièrement des ajouts"}</span>{" à celui-ci. Une partie du site est encore avec un contenu 'test', merci par avance de votre compréhension."}
          </p>
           <p>
            <span className='bold'>{"Dance Riser"}</span> {" est une plateforme créée dans le "} <span className='bold'>{" but d'offrir un outil de travail et d'entraînement aux utilisateurs, à travers une bibliothèque de tutoriels vidéos"}</span>{", expliquants comment apprendre et réaliser différents mouvements de danse. "} <span className='bold'>{"Pour avoir accès à cette bibliothèque vous devez d'abord vous enregister en tant qu'utilisateur et vous connecter."}</span> 
           </p>
           <p>
           <span className='bold'>{"Dance Riser"}</span>{" offre également aux"} <span className='bold'>{"écoles, aux groupes et aux crews, la possibilité de s'enregister sur la plateforme"}</span>{", afin de pouvoir gérer leurs membres et partager avec eux, des chorégraphies privées."} <span className='bold'>{"Pour pouvoir soumettre son école/groupe/crew, il faut que le manager/coach s'inscrive sur le site, se connecte et remplisse la demande d'adhésion située en bas de la page."}</span>{" Attention un utilisateur appartenant déjà à un groupe, ne peut pas en soumettre un. Pour cela il faudra créer un nouveau compte dédié à la gestion de ce nouveau groupe."} 
            </p>
            <p>
            <span className='bold'>{"En tant qu'utilisateur, si vous souhaitez rejoindre une école, un groupe ou un crew, vous trouverez un formulaire de demande d'adhésion en bas de la page du groupe voulu, dans la section 'Nos écoles'."}</span> {"Nous vous conseillons de prendre contact avec celui-ci avant de faire votre demande. Vous avez la possibilité d'appartenir à plusieurs écoles et crews. Nous vous conseillons d'en avertir les écoles concernées si tel est le cas."}
            </p>
            <p>
            <span className='bold'>{"Dance Riser"}</span>{" sera en constante évolution, afin de pouvoir vous offrir toujours plus d'outils pour vous aider à progresser dans les meilleurs conditions qu'il soit."}
            </p>
       </section>
       {loading && <Loading/>}
        {!loading && eventList.events[0] && (
          <div className='wrapper'>
            <h2 className='eventTitle'>{"Nos prochains évènements"}</h2>
            <section className='eventsHome'>
                {!loading && eventList.events.map((event) => (
                  <ImageCard
                    key={event.slug}
                    title={event.name}
                    description={event.description}
                    date={"le "+eventList.dates[event.id]}
                    lien={event.image}
                    link={'/events/'+event.slug+'/'+event.id}
                  />
                ))}
            </section>
          </div>
        )}
    </div>
 )
}
export default Home;