import React from "react";
import "./HelpAgenda.scss";

function HelpAgenda() {
  return (
    <div className="HelpAgenda">
      <h2>{"Aide ajout agenda :"}</h2>
      <p>{"Sur Dance Riser l'agenda est lié à l'utilisation d'un compte Gmail. Il vous faudra donc créer un compte Gmail si vous souhaitez exploiter cette fonctionnalité. Si vous souhaiter en créer un, c'est "} <a className="link" href="https://support.google.com/mail/answer/56256?hl=fr" target='_blank' rel='noreferrer'>{"par ici"}</a>{". Une fois en possession de votre compte vous allez pouvoir suivre le guide. (à notez que les groupes/écoles/crews, possèdent un agenda personnel, et ont la possibilité d'ajouter un agenda visible par tous leurs membres dans l'onglet gestion.) "}</p>

      <h3>{"Étape 1:"}</h3>
      <p>suite</p>
    </div>
  );
}
export default HelpAgenda;
