import React from 'react';
import './CGU.scss';


function CGU() {


  return(
    <div className='CGU'>

       <h2>{"Conditions Générales d'Utilisation"}</h2>

       <p>Les présentes CGU ou Conditions Générales d’Utilisation encadrent juridiquement l’utilisation des services du site Dance Riser (ci-après dénommé « le site »).</p>
       <p>Constituant le contrat entre la société Dance Riser, l’Utilisateur, l’accès au site doit être précédé de l’acceptation de ces CGU. L’accès à cette plateforme signifie l’acceptation des présentes CGU.</p>

       <h3>Objectif du site:</h3>
       <p>Le site a pour objectif de proposer un support permettant d’apprendre la danse, via des tutoriels vidéos, et de pouvoir communiquer entre apprenants et professeurs.</p>

       <h3>Les services:</h3>

       <h4>En tant que visiteur:</h4>
       <p>Vous avez la possibilité de:</p>
       <ul>
         <li>Vous inscrire au site, ou de vous connecter à votre compte existant.</li>
         <li>Consulter une liste d’évènements, ainsi que leurs lieux.</li>
         <li>Consulter une liste d’écoles, et consulter les détails la concernant.</li>
       </ul>
       <p>Pour avoir accès aux contenus du site vous devez créer un compte. Grâce à celui-ci vous pourrez consulter une bibliothèque de vidéos tutoriels pour apprendre les techniques de base de danse (dans un premier temps seul le Hip-Hop et Break-dance sont disponibles).</p>

       <h4>En tant qu’utilisateur (connecté):</h4>
       <p>Vous avez la possibilité de:</p>
       <ul>
         <li>Consulter la bibliothèque de mouvements du site.</li>
         <li>Contacter un groupe, un crew ou une école afin de l’intégrer, ou obtenir des informations dessus.</li>
         <li>Faire une demande d’intégration au site, d’un groupe, d’un crew ou d’une école.</li>
         <li>D’avoir une page personnelle pour prendre des notes et créer votre propre passage/ chorégraphie ( en cours de réalisation).</li>
       </ul>
       <p>Pour avoir accès aux contenus des supports proposés par les groupes/crews/écoles, il faut que votre demande d’adhésion ait été acceptée.</p>

       <h4>En tant que membre (d’un(e) groupe/crew/école):</h4>
       <p>Vous avez la possibilité de:</p>
       <ul>
         <li>Consulter la bibliothèque de chorégraphies proposées par le(la) groupe/crew/école.</li>
         <li>Discuter via une interface de messagerie instantanée avec les autres membres de son groupe/crew/école (en cours de réalisation).</li>
       </ul>
       <p>Lorsque votre demande d’intégration d’un (e) groupe/crew/école est acceptée, votre compte utilisateur devient administrateur de celui(lle)-ci.</p>
       
       <h4>En tant que responsable d’un(e) groupe/crew/école:</h4>
       <p>Vous avez la possibilité de:</p>
       <ul>
         <li>Gérer la description de votre groupe/crew/école ajouter des vidéos de chorégraphies.</li>
         <li>Gérer les membres de votre groupe/crew/école.</li>
         <ul>
            <li>Ajouter ou retirer un membre de votre groupe/crew/école.</li>
            <li>Attribuer un niveau (débutant, intermédiaire, avancé…)</li>
         </ul>
       </ul>
       
       <h3>Les droits de l’utilisateur:</h3>
       <p>Lorsque vous créez un compte utilisateur sur Dance Riser, vous devez respecter les règles suivantes:</p>
       <ul>
         <li>Maintenir le caractère confidentiel de ses identifiants de connexion,</li>
         <li>Utiliser le site conformément à sa destination,</li>
         <li>Ne pas tenter de nuire au bon fonctionnement du site.</li>
         <li>Ne pas diffuser de vidéos contenant des éléments inappropriés, et n’allant pas avec l’objectif de Dance Riser.</li>
         <li>Respecter l’usage du canal de discussion, en restant poli et courtois.</li>
         <li>Ne pas créer de faux comptes, ou de faux groupes.</li>
       </ul>
       <p>Le non-respect de ces règles entraînera des sanctions.</p>
       
       <h3>Propriété intellectuelle:</h3>
       <p>Les marques, logos ainsi que les contenus du site Dance Riser (illustrations graphiques, textes…) sont protégés par le Code de la propriété intellectuelle et par le droit d’auteur.</p>
       <p>Toutes les informations disponibles sur le site relèvent de la propriété de l’éditeur et sont protégées par le droit de la propriété intellectuelle et plus particulièrement le droit d’auteur.</p>
       <p>La reproduction et la copie des contenus par l’utilisateur requièrent une autorisation préalable du site.</p>
       <p>Toute utilisation totale ou partielle du site ou de son contenu, par quelque procédé que ce soit ou sur quelque support que ce soit, à des fins commerciales ou publicitaires, est interdite et engage la responsabilité de l’utilisateur.</p>
       <p>Les informations figurant sur le site sont uniquement disponibles à des fins de consultation par les utilisateurs, à défaut d’un accord préalable et exprès.</p>
       
       <h3>Responsabilité:</h3>
       <p>Bien que les informations publiées sur le site soient réputées fiables, le site se réserve la faculté d’une non-garantie de la fiabilité des sources.</p>
       <p>Les informations diffusées sur le site Dance Riser sont présentées à titre purement informatif et sont sans valeur contractuelle. En dépit des mises à jour régulières, la responsabilité du site ne peut être engagée en cas de modification des dispositions administratives et juridiques apparaissant après la publication. </p>
       <p>Il en est de même pour l’utilisation et l’interprétation des informations communiquées sur la plateforme.</p>
       <p>Le site décline toute responsabilité concernant les éventuels virus pouvant infecter le matériel informatique de l’utilisateur après l’utilisation ou l’accès à ce site.</p>
       <p>Le site ne peut être tenu pour responsable en cas de force majeure ou du fait imprévisible et insurmontable d’un tiers.</p>
       <p>La garantie totale de la sécurité et la confidentialité des données n’est pas assurée par le site. Cependant, le site s’engage à mettre en œuvre toutes les méthodes requises pour le faire au mieux.</p>

       <h4>Publications par l’utilisateur:</h4>
       <p>Le site dispose du droit d’exercer une modération à priori sur les publications et peut refuser leur mise en ligne sans avoir à fournir de justification.</p>
       <p>Le site décline toute responsabilité concernant les éventuels utilisateurs publiant du contenu erroné ou des propos injurieux. Vous pouvez toutefois tenir informé Dance Riser de l’existence d’un tel contenu afin qu’il soit modéré.</p>
       <p>Le membre garde l’intégralité de ses droits de propriété intellectuelle. Toutefois, toute publication sur le site implique la délégation du droit non exclusif et gratuit à la société éditrice de représenter, reproduire, modifier, adapter, distribuer et diffuser la publication n’importe où et sur n’importe quel support pour la durée de la propriété intellectuelle. Cela peut se faire directement ou par l’intermédiaire d’un tiers autorisé. Cela concerne notamment le droit d’utilisation de la publication sur le web et sur les réseaux de téléphonie mobile.</p>
       <p>À chaque utilisation, l’éditeur s’engage à mentionner le nom du membre à proximité de la publication.</p>
       <p>L’utilisateur est tenu responsable de tout contenu qu’il met en ligne. L’utilisateur s’engage à ne pas publier de contenus susceptibles de porter atteinte aux intérêts de tierces personnes. Toutes procédures engagées en justice par un tiers lésé à l’encontre du site devront être prises en charge par l’utilisateur.</p>
       <p>La suppression ou la modification par le site du contenu de l’Utilisateur peut s’effectuer à tout moment, pour n’importe quelle raison et sans préavis.</p>

       <h4>Lien hypertextes:</h4>
       <p>Le site peut être constitué de liens hypertextes. En cliquant sur ces derniers, l’Utilisateur sortira de la plateforme. Cette dernière n’a pas de contrôle et ne peut pas être tenue responsable du contenu des pages web relatives à ces liens.</p>
       
       <h3>Droit applicable et juridiction compétente:</h3>
       <p>Le présent contrat est valable pour une durée indéterminée. Le début de l’utilisation des services du site marque l’application du contrat à l’égard de l’utilisateur.</p>
       <p>Le présent contrat est soumis à la législation française. L’absence de résolution à l’amiable des cas de litige entre les parties implique le recours aux tribunaux français compétents pour régler le contentieux</p>
    </div>
 )
}
export default CGU;