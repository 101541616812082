export const SET_ALL_LOADING_FALSE = 'SET_ALL_LOADING_FALSE';
export const setAllLoadingFalse = () => ({
  type: SET_ALL_LOADING_FALSE,
});


// toggle la nav.
export const SET_NAV = 'SET_NAV';
export const setNav = () => ({
  type: SET_NAV,
});

// toggle la nav.
export const CLOSE_NAV = 'CLOSE_NAV';
export const closeNav = () => ({
  type: CLOSE_NAV,
});

// déconnexion.
export const LOGOUT = 'LOGOUT';
export const logout = () => ({
  type: LOGOUT,
});

// change les input (user) de l'inscription/connexion.
export const CHANGE_INPUT = 'CHANGE_INPUT';
export const changeInput = (name, value) => ({
  type: CHANGE_INPUT,
  name: name,
  value: value,
});

// change les input (user) de l'inscription/connexion.
export const CHANGE_PDP = 'CHANGE_PDP';
export const changePDP = ( value) => ({
  type: CHANGE_PDP,
  value: value,
});

// change les inputs du formulaire de contact.
export const CHANGE_FORM_INPUT = 'CHANGE_FORM_INPUT';
export const changeFormInput = (name, value) => ({
  type: CHANGE_FORM_INPUT,
  name: name,
  value: value,
});

// envoie le formulaire de contact.
export const SUBMIT_CONTACT_FORM = 'SUBMIT_CONTACT_FORM';
export const submitContactForm = () => ({
  type: SUBMIT_CONTACT_FORM,
});


// ouvre/ferme les events filter.
export const SET_EVENT_FILTER = 'SET_EVENT_FILTER';
export const setEventFilter = () => ({
  type: SET_EVENT_FILTER,
});

// ouvre/ferme les events filter.
export const SET_SCHOOL_FILTER = 'SET_SCHOOL_FILTER';
export const setSchoolFilter = () => ({
  type: SET_SCHOOL_FILTER,
});


// FORMULAIRE ADHESION DE L'ECOLE

// change les inputs du formulaire de demande d'hadèsion d'école.
export const CHANGE_SCHOOL_FORM_INPUT = 'CHANGE_SCHOOL_FORM_INPUT';
export const changeSchoolFormInput = (name, value) => ({
  type: CHANGE_SCHOOL_FORM_INPUT,
  name: name,
  value: value,
});

export const CHANGE_SCHOOL_COURSE_FORM_INPUT = 'CHANGE_SCHOOL_COURSE_FORM_INPUT';
export const changeCourseFormInput = (name, value) => ({
  type: CHANGE_SCHOOL_COURSE_FORM_INPUT,
  name: name,
  value: value,
});

export const CHANGE_SCHOOL_LOCATION_FORM_INPUT = 'CHANGE_SCHOOL_LOCATION_FORM_INPUT';
export const changeLocationFormInput = (index, value) => ({
  type: CHANGE_SCHOOL_LOCATION_FORM_INPUT,
  index: index,
  value: value,
});

export const CHANGE_SCHOOL_OPENTO_FORM_INPUT = 'CHANGE_SCHOOL_OPENTO_FORM_INPUT';
export const changeOpenToFormInput = (name, value) => ({
  type: CHANGE_SCHOOL_OPENTO_FORM_INPUT,
  name: name,
  value: value,
});

export const CHANGE_SCHOOL_LEVEL_FORM_INPUT = 'CHANGE_SCHOOL_LEVEL_FORM_INPUT';
export const changeLevelFormInput = (name, value) => ({
  type: CHANGE_SCHOOL_LEVEL_FORM_INPUT,
  name: name,
  value: value,
});

export const REMOVE_SCHOOL_FORM = 'REMOVE_SCHOOL_FORM';
export const removeSchoolForm = () => ({
  type: REMOVE_SCHOOL_FORM,
});

export const ADD_SCHOOL_FORM = 'ADD_SCHOOL_FORM';
export const AddSchoolForm = () => ({
  type: ADD_SCHOOL_FORM,
});


export const ADD_SCHOOL_LOCATION = 'ADD_SCHOOL_LOCATION';
export const addSchoolLocation = (index) => ({
  type: ADD_SCHOOL_LOCATION,
  index: index,
});

export const DELETE_SCHOOL_LOCATION = 'DELETE_SCHOOL_LOCATION';
export const DeleteSchoolLocation = (array) => ({
  type: DELETE_SCHOOL_LOCATION,
  array: array,
});

export const CHANGE_PDP_SCHOOL = 'CHANGE_PDP_SCHOOL';
export const changePDPSchool = (value) => ({
  type: CHANGE_PDP_SCHOOL,
  value: value,
});
// FORMULAIRE ADHESION DE L'ECOLE

export const MODIFY_SETTINGS_PROFILE = 'MODIFY_SETTINGS_PROFILE';
export const modifySettingsProfile = () => ({
  type: MODIFY_SETTINGS_PROFILE,
});

export const CLOSE_SETTINGS_PROFILE = 'CLOSE_SETTINGS_PROFILE';
export const closeSettingsProfile = () => ({
  type: CLOSE_SETTINGS_PROFILE,
});

export const CHANGE_CHORE_LEVEL_SEARCH = 'CHANGE_CHORE_LEVEL_SEARCH';
export const ChangeChoreLevelSearch = (name, value) => ({
  type: CHANGE_CHORE_LEVEL_SEARCH,
  name: name,
  value: value,
});

// ouvre/ferme les choreography filter.
export const SET_CHOREO_FILTER = 'SET_CHOREO_FILTER';
export const setChoreoFilter = () => ({
  type: SET_CHOREO_FILTER,
});


// change les inputs du formulaire de user settings.
export const CHANGE_USER_SETTINGS_INPUT = 'CHANGE_USER_SETTINGS_INPUT';
export const changeUserSettingsInput = (name, value) => ({
  type: CHANGE_USER_SETTINGS_INPUT,
  name: name,
  value: value,
});

// change les input (user) de l'inscription/connexion.
export const CHANGE_PDP_SETTINGS = 'CHANGE_PDP_SETTINGS';
export const changePDPSettings = ( value) => ({
  type: CHANGE_PDP_SETTINGS,
  value: value,
});


export const CHANGE_MOVES_LEVEL_SEARCH = 'CHANGE_MOVES_LEVEL_SEARCH';
export const ChangeMovesLevelSearch = (name, value) => ({
  type: CHANGE_MOVES_LEVEL_SEARCH,
  name: name,
  value: value,
});

// ouvre/ferme les choreography filter.
export const SET_MOVES_FILTER = 'SET_MOVES_FILTER';
export const setMovesFilter = () => ({
  type: SET_MOVES_FILTER,
});

// ouvre/ferme les choreography filter.
export const CALL_API = 'CALL_API';
export const callApi = () => ({
  type: CALL_API,
});

// change la valeur du message sur la page school
export const CHANGE_SCHOOL_SUBMIT_MESSAGE = 'CHANGE_SCHOOL_SUBMIT_MESSAGE';
export const changeSchoolSubmitMessage = (value) => ({
  type: CHANGE_SCHOOL_SUBMIT_MESSAGE,
  value: value,
});

// change la valeur de la checkbox sur la page school
export const CHANGE_SCHOOL_SUBMIT_CHECKBOX = 'CHANGE_SCHOOL_SUBMIT_CHECKBOX';
export const changeSchoolSubmitCheckbox = () => ({
  type: CHANGE_SCHOOL_SUBMIT_CHECKBOX,
});

// change la valeur de la checkbox sur la page school
export const CHANGE_EVENTS_SEARCH_INPUT = 'CHANGE_EVENTS_SEARCH_INPUT';
export const changeEventsSearchInput = (name, value) => ({
  type: CHANGE_EVENTS_SEARCH_INPUT,
  name: name,
  value: value,
});

// _______________________________
//EVENT
export const RETURN_EVENTS_FILTERED = 'RETURN_EVENTS_FILTERED';
export const returnEventsFiltered = ( data, name ) => ({
  type: RETURN_EVENTS_FILTERED,
  data: data,
  name: name,
});

export const EVENTS_SEARCH_SUBMIT = 'EVENTS_SEARCH_SUBMIT';
export const eventSearchSubmit = (data) => ({
  type: EVENTS_SEARCH_SUBMIT,
  data: data,
});

export const EVENTS_RESET_SUBMIT = 'EVENTS_RESET_SUBMIT';
export const eventResetSubmit = (data) => ({
  type: EVENTS_RESET_SUBMIT,
  data: data,
});

export const SET_EVENT_INPUT_SESSION_STORAGE = 'SET_EVENT_INPUT_SESSION_STORAGE';
export const setEventInputSessionStorage = (name, postalCode, city) => ({
  type: SET_EVENT_INPUT_SESSION_STORAGE,
  name: name,
  postalCode: postalCode,
  city: city,
});

// _______________________________
//SCHOOL
export const CHANGE_SCHOOL_SEARCH_INPUT = 'CHANGE_SCHOOL_SEARCH_INPUT';
export const changeSchoolSearchInput = (name, value) => ({
  type: CHANGE_SCHOOL_SEARCH_INPUT,
  name: name,
  value: value,
});

export const SCHOOL_SEARCH_SUBMIT = 'SCHOOL_SEARCH_SUBMIT';
export const schoolSearchSubmit = (data) => ({
  type: SCHOOL_SEARCH_SUBMIT,
  data: data,
});

export const SCHOOL_RESET_SUBMIT = 'SCHOOL_RESET_SUBMIT';
export const schoolResetSubmit = (data) => ({
  type: SCHOOL_RESET_SUBMIT,
  data: data,
});

export const SET_SCHOOL_INPUT_SESSION_STORAGE = 'SET_SCHOOL_INPUT_SESSION_STORAGE';
export const setSchoolInputSessionStorage = (name, postalCode, city) => ({
  type: SET_SCHOOL_INPUT_SESSION_STORAGE,
  name: name,
  postalCode: postalCode,
  city: city,
});

// _______________________________
//CHOREO

export const CHANGE_CHOREO_SEARCH_INPUT = 'CHANGE_CHOREO_SEARCH_INPUT';
export const changeChoreoSearchInput = (name, value) => ({
  type: CHANGE_CHOREO_SEARCH_INPUT,
  name: name,
  value: value,
});

export const CHOREO_FILTRES = 'CHOREO_FILTRES';
export const choreoFiltres = (data) => ({
  type: CHOREO_FILTRES,
  data: data,
});

export const SET_CHORE_INPUT_SESSION_STORAGE = 'SET_CHORE_INPUT_SESSION_STORAGE';
export const setChoreInputSessionStorage = (name, data) => ({
  type: SET_CHORE_INPUT_SESSION_STORAGE,
  name: name,
  data: data,
});
// _______________________________
//MOVES

export const CHANGE_MOVES_SEARCH_INPUT = 'CHANGE_MOVES_SEARCH_INPUT';
export const changeMovesSearchInput = (name, value) => ({
  type: CHANGE_MOVES_SEARCH_INPUT,
  name: name,
  value: value,
});

export const MOVES_FILTRES = 'MOVES_FILTRES';
export const movesFiltres = (data) => ({
  type: MOVES_FILTRES,
  data: data,
});

export const SET_MOVE_INPUT_SESSION_STORAGE = 'SET_MOVE_INPUT_SESSION_STORAGE';
export const setMoveInputSessionStorage = (name, data) => ({
  type: SET_MOVE_INPUT_SESSION_STORAGE,
  name: name,
  data: data,
});

// _______________________________
//MOT DE PASSES
export const CHANGE_NEW_PASS_INPUTS = 'CHANGE_NEW_PASS_INPUTS';
export const changeNewPassInputs = (name, value) => ({
  type: CHANGE_NEW_PASS_INPUTS,
  name: name,
  value: value,
});
