import React, { useEffect } from 'react';
import ImageCard from '../ImageCard/ImageCard';
import './ChoreographyStyles.scss';
import { useDispatch, useSelector } from 'react-redux';
import { callChoreographysStylesList } from '../../actions/api';
import Loading from '../Loading/Loading';
import { setAllLoadingFalse } from '../../actions/action';

function ChoreographyStyles() {
  const dispatch = useDispatch();
  const stylesList = useSelector((state) => state.reducer.choreStyleList)
  const loading = useSelector((state) => state.reducer.loading.loadingChoreStyleList)

  useEffect(() => {
    dispatch(setAllLoadingFalse())
  }, []);


  useEffect(() => {
    setTimeout(() => {
      if(localStorage.getItem("token")) {
        dispatch(callChoreographysStylesList())
      }else{
        window.location.href = '/login';
      }
    }, 100);
  }, [])


  return(
    <div className='ChoreographyStyles'>
    {loading && <Loading/>}
    <h2>{"Choreography Styles :"}</h2>
      
       <section className='styleList'>

         {!loading && stylesList.map((style) => (
            <ImageCard
              key={style.name}
              title={style.name}
              lien={style.image}
              link={'/choreography/styles/'+style.slug}
            />
         ))}

      </section>
    </div>
 )
}
export default ChoreographyStyles;