import axios from 'axios';
import {
  setLoading, makeUndefined,
  CALL_SCHOOLS, saveSchools,
  CALL_ONE_SCHOOL, saveOneSchool,
  CALL_EVENTS, saveEvents,
  CALL_ONE_EVENT, saveOneEvents,
  CALL_HOME, saveHome,
  CALL_CHOREOGRAPHYS_STYLES_LIST, saveChoreographysStylesList,
  CALL_CHOREOGRAPHYS_LIST, saveChoreographysList,
  CALL_ONE_CHOREOGRAPHY, saveOneChoregraphy,
  CALL_MOVES_STYLES_LIST,saveMovesStylesList,
  CALL_MOVES_CATEGORIES_LIST,saveMovesCategoriesList,
  CALL_MOVES_LIST,saveMovesList,
  CALL_ONE_MOVE,saveOneMove,
  SEND_ADHESION_SCHOOLS,
  SEND_SUBSCRIBE_SCHOOL_FORM,
  SEND_LOGIN_FORM,saveLogin,
  SEND_SUBSCRIBE_FORM, saveLoginDetail,
  SEND_DETAIL_PROFIL,
  SEND_NEW_PASS_FORM
} from "../actions/api";

import {
  logout,
} from "../actions/action";

// eslint-disable-next-line no-undef
const cleAPI = process.env.REACT_APP_API_URL;

const apis = (store) => (next) => (action) => {
  switch (action.type) {
    case CALL_SCHOOLS:{
        axios.get(cleAPI+'/schools')
        .then((res) => {
          store.dispatch(saveSchools(res.data))
        })
        .catch((error) => {
          console.log(error);
          store.dispatch(makeUndefined('schoolList'));
          window.location.href = 'error404';
        })
        .finally(() => {
          store.dispatch(setLoading('loadingSchools', false))
        })
        next(action);
    break;
    }
    case CALL_ONE_SCHOOL:{
        axios.get(cleAPI+'/schools/'+action.slug+'/'+action.id)
        .then((res) => {
          store.dispatch(saveOneSchool(res.data));
        })
        .catch((error) => {
          console.log(error);
          store.dispatch(makeUndefined('schoolPage'));
          window.location.href = 'error404';
        })
        .finally(() => {
          store.dispatch(setLoading('loadingSchool', false));
        })
        next(action);
    break;
    }
    case CALL_EVENTS:{
        axios.get(cleAPI+'/events')
        .then((res) => {
          store.dispatch(saveEvents(res.data))
        })
        .catch((error) => {
          console.log(error);
          store.dispatch(makeUndefined('eventList'));
          window.location.href = 'error404';
        })
        .finally(() => {
          store.dispatch(setLoading('loadingEvents', false))
        })
        next(action);
    break;
    }
    case CALL_ONE_EVENT:{
        axios.get(cleAPI+'/events/'+action.slug+'/'+action.id)
        .then((res) => {
          store.dispatch(saveOneEvents(res.data))
        })
        .catch((error) => {
          console.log(error);
          store.dispatch(makeUndefined('eventPage'));
          window.location.href = 'error404';
        })
        .finally(() => {
          store.dispatch(setLoading('loadingEvent', false))
        })
        next(action);
    break;
    }
    case CALL_HOME:{
      axios.get(cleAPI+'/home')
      .then((res) => {
        store.dispatch(saveHome(res.data))
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        store.dispatch(setLoading('loadingHomeEvents', false))
      })
      next(action);
    break;
    }
    case CALL_CHOREOGRAPHYS_STYLES_LIST:{
      const { token } = store.getState().reducer.user
      axios.get(cleAPI+'/styles',
      {headers: {
        Authorization: `Bearer ${token}`
      }}
      )
      .then((res) => {
        store.dispatch(saveChoreographysStylesList(res.data))
      })
      .catch((error) => {
        console.log(error);
        store.dispatch(makeUndefined('choreStyleList'));
        window.location.href = 'error404';
      })
      .finally(() => {
        store.dispatch(setLoading('loadingChoreStyleList', false))
      })
      next(action);
    break;
    }
    case CALL_CHOREOGRAPHYS_LIST:{
      const { token } = store.getState().reducer.user
      axios.get(cleAPI+'/'+action.slug+'/choreography',
      {headers: {
        Authorization: `Bearer ${token}`
      }}
      )
      .then((res) => {
        store.dispatch(saveChoreographysList(res.data))
      })
      .catch((error) => {
        console.log(error);
        store.dispatch(makeUndefined('choreStylePage'));
        window.location.href = 'error404';
      })
      .finally(() => {
        store.dispatch(setLoading('loadingChoreStylePage', false))
      })
      next(action);
    break;
    }
    case CALL_ONE_CHOREOGRAPHY:{
      const { token } = store.getState().reducer.user
      axios.get(cleAPI+'/choreography/'+action.slug+'/'+action.id,
      {headers: {
        Authorization: `Bearer ${token}`
      }}
      )
      .then((res) => {
        store.dispatch(saveOneChoregraphy(res.data))
      })
      .catch((error) => {
        console.log(error);
        store.dispatch(makeUndefined('chorePage'));
        window.location.href = 'error404';
      })
      .finally(() => {
        store.dispatch(setLoading('loadingChore', false))
      })
      next(action);
    break;
    }
    case CALL_MOVES_STYLES_LIST:{
      const { token } = store.getState().reducer.user
      axios.get(cleAPI+'/styles',
      {headers: {
        Authorization: `Bearer ${token}`
      }}
      )
      .then((res) => {
        store.dispatch(saveMovesStylesList(res.data))
      })
      .catch((error) => {
        console.log(error);
        store.dispatch(makeUndefined('moveStyleList'));
        window.location.href = 'error404';
      })
      .finally(() => {
        store.dispatch(setLoading('loadingMovesStyleList', false))
      })
      next(action);
    break;
    }
    case CALL_MOVES_CATEGORIES_LIST:{
      const { token } = store.getState().reducer.user
      axios.get(cleAPI+'/'+action.slug+'/category/moves',
      {headers: {
        Authorization: `Bearer ${token}`
      }}
      )
      .then((res) => {
        store.dispatch(saveMovesCategoriesList(res.data))
      })
      .catch((error) => {
        console.log(error);
        store.dispatch(makeUndefined('moveStylePage'));
        window.location.href = 'error404';
      })
      .finally(() => {
        store.dispatch(setLoading('loadingMovesStylePage', false))
      })
      next(action);
    break;
    }
    case CALL_MOVES_LIST:{
      const { token } = store.getState().reducer.user
      axios.get(cleAPI+'/'+action.slug+'/moves',
      {headers: {
        Authorization: `Bearer ${token}`
      }})
      .then((res) => {
        store.dispatch(saveMovesList(res.data))
      })
      .catch((error) => {
        console.log(error);
        store.dispatch(makeUndefined('movesListPage'));
        window.location.href = 'error404';
      })
      .finally(() => {
        store.dispatch(setLoading('loadingMovesCategoriesPage', false))
      })
      next(action);
    break;
    }
    case CALL_ONE_MOVE:{
      const { token } = store.getState().reducer.user
      const config = {headers: {
        Authorization: `Bearer ${token}`
      }}
      axios.get(cleAPI+'/moves/'+action.slug+'/'+action.id,
      config
      )
      .then((res) => {
        store.dispatch(saveOneMove(res.data));
      })
      .catch((error) => {
        console.log(error);
        store.dispatch(makeUndefined('movePage'));
        window.location.href = 'error404';
      })
      .finally(() => {
        store.dispatch(setLoading('loadingMoves', false))
      })
      next(action);
    break;
    }
    case SEND_ADHESION_SCHOOLS:{
      const { token } = store.getState().reducer.user
      const bodyParameter = {
        comment: action.data.comment,
        school: action.data.school_id,
        user: action.data.user_id,
      }
      const config = {headers: {
        Authorization: `Bearer ${token}`
      }}
       axios.post(cleAPI+'/newMember',
       bodyParameter,
       config
      )
      .then((res) => {
        store.dispatch(saveOneMove(res.data));
        window.location.href = '/validate';
      })
      .catch((error) => {
        console.log(error);
        window.location.href = 'error404';
      })
      .finally(() => {
      })
      next(action);
    break;
    }
    case SEND_SUBSCRIBE_SCHOOL_FORM:{
      const { token, role, schoolList } = store.getState().reducer.user
      if (role === 'ROLE_MEMBER' && !schoolList[0]) {
        
        const config = {headers: {
          Authorization: `Bearer ${token}`
        }}
        const bodyParameter = {
          commentRequest: action.comment,
          name: action.name,
          description: action.description,
          email: action.email,
          phone: action.phone,
          image: action.image,
          lessonType: action.lessonType,
          locations: action.locations,
          openTo: action.openTo,
          level: action.level,
        }
        axios.post(cleAPI+'/newSchool', bodyParameter, config)
        .then(() => {
        window.location.href = '/validate';
      })
      .catch((error) => {
        console.log(error);
       // window.location.href = 'error404';
      })
      .finally(() => {
      })
      next(action);
    }else{
      next(action);
    }
    break;
    }
    case SEND_LOGIN_FORM:{
      axios.post(cleAPI+'/login_check', {
        username: action.data.username,
        password: action.data.password,
      })
      .then((res) => {
        axios.get(cleAPI+'/users/'+res.data.id+'/school',
        {headers: {
          Authorization: `Bearer ${res.data.token}`
        }}
        )
        .then((response) => {

          axios.get(cleAPI+'/users/'+res.data.id+'/level',
          {headers: {
            Authorization: `Bearer ${res.data.token}`
          }})
          .then((res3) => {
            store.dispatch(saveLogin(
              res.data.email,
              res.data.id,
              res.data.blaze,
              res.data.firstname,
              res.data.lastname,
              res.data.roles[0],
              response.data,
              res.data.activated,
              res3.data.name,
              res.data.token,
              res.data.avatar,
              res.data.agendaLink,
            ));
            store.dispatch(saveLoginDetail(
              res.data.email,
              res.data.blaze,
              res.data.firstname,
              res.data.lastname,
              res.data.avatar,
              res.data.agendaLink,
            ))
            localStorage.setItem("email", res.data.email);
            localStorage.setItem("id", res.data.id);
            localStorage.setItem("blaze", res.data.blaze);
            localStorage.setItem("firstname", res.data.firstname);
            localStorage.setItem("lastname", res.data.lastname);
            localStorage.setItem("roles", res.data.roles[0]);
            localStorage.setItem("blaze", res.data.blaze);
            localStorage.setItem("schoolList", JSON.stringify(response.data));
            localStorage.setItem("activated", res.data.activated);
            localStorage.setItem("level", res3.data.name);
            localStorage.setItem("token", res.data.token);
            localStorage.setItem("avatar", res.data.avatar);
            localStorage.setItem("agendaLink", res.data.agendaLink);
            localStorage.setItem("temps", (Date.now() + 3500000));
          })
        })
      })
      .catch((error) => {
        console.log(error);
        const warningElement = document.querySelector("#root > div > div.Login > form > div:nth-child(3) > p")
        warningElement.className = 'warning'
        warningElement.textContent = "L'identifiant et/ou le mot de passe sont erronés."
      })
      .finally(() => {
      })
      next(action);
    break;
    }
    case SEND_SUBSCRIBE_FORM:{
    
      axios.post(cleAPI+'/subscribe', {
        firstname: action.firstname,
        lastname: action.lastname,
        blaze: action.blaze,
        avatar: action.avatar,
        email: action.email,
        password: action.password,
      })
      .then(() => {
        window.location.href = '/mailValidation';
      })
      .catch((error) => {
        console.log(error.response.data);
        console.log(action)
        window.location.href = 'error404';
      })
      .finally(() => {
      })
      next(action);
    break;
    }
    case SEND_DETAIL_PROFIL:{
      const { token, id } = store.getState().reducer.user
      const bodyParameter = {
        firstname: action.firstName,
        lastname: action.lastName,
        blaze: action.blaze,
        avatar: action.avatar,
        email: action.email,
        agendaLink: action.agendaLink,
      }
      const config = {headers: {
        Authorization: `Bearer ${token}`
      }}
      axios.patch(cleAPI+'/users/'+id,
        bodyParameter,
        config
      )
      .then(() => {
        store.dispatch(logout());
        window.location.href = '/login';

      })
      .catch((error) => {
        console.log(error);
        window.location.href = 'error404';
      })
      .finally(() => {
      })
      next(action);
    break;
    }
    case SEND_NEW_PASS_FORM:{
      const { password } = store.getState().reducer.webSite.changePassword
      const { id, token } = store.getState().reducer.user
      const config = {headers: {
        Authorization: `Bearer ${token}`
      }}
      axios.patch(cleAPI+'/change-password/'+id,
      {
        password: password,
      }, config
      )
      .then(() => {
        store.dispatch(logout());
        window.location.href = '/login';
      })
      .catch((error) => {
        console.log(error);
        window.location.href = 'error404';
      })
      .finally(() => {
      })
      next(action);
    break;
    }
    
    default:
    next(action);
    break;
  }
};

export default apis;