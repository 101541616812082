import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { changeInput, logout } from '../../actions/action';
import { sendLoginForm } from '../../actions/api';
import closedEye from '../../assets/Images/yeux.png'
import openedEye from '../../assets/Images/oeil.png'
import './Login.scss';


function Login() {

   const dispatch = useDispatch();
   const navigate = useNavigate();
   const user = useSelector((state) => state.reducer.user)
  
   useEffect(() => {
      if (user.isLogged) {
         if (!user.activated){
            localStorage.clear();
            const warningElement = document.querySelector("#root > div > div.Login > form > div:nth-child(3) > p")
            warningElement.className = 'warning'
            warningElement.textContent = "Votre compte n'a pas encore été activé, veuillez vérifier vos mails et cliquer sur le lien d'activation (pensez à regarder dans vos spams). En cas de doute, contactez l'administrateur via le formulaire de contact."
            return dispatch(logout());
         }
         navigate('/user');
      }
   }, [user.isLogged])

   const handleSubmit = async (e) => {
      e.preventDefault()
      
        dispatch(sendLoginForm({
            username: user.email,
            password: user.password,
         }));
      
 }

   const showPassword1 = () => {
      const passwordElement = document.querySelector("#show1");
      const eyeElement = document.querySelector("#eye1");
      if (passwordElement.type === "password") {
        eyeElement.src = openedEye
        passwordElement.type = "text";
      } else {
        eyeElement.src = closedEye
        passwordElement.type = "password";
      }
    } 

   const handleChange = (e) => {
    dispatch(changeInput(e.target.name, e.target.value))
   }

  return(
   <div className='Login'>

      <form onSubmit={(e) => {handleSubmit(e).then()}}>
         <h2>Se connecter</h2>

         <div className="inputLabel">
            <label htmlFor="email">Email</label>
            <input onChange={handleChange} type="email" name='email' />
         </div>

         <div className="inputLabel">
            <label htmlFor="password">Mot de passe</label>
            <div className='passwordWraper'>
            <input id='show1' className='password' onChange={handleChange} type="password" name='password' autoComplete={"current-password"}  required/>
            <img id='eye1' onClick={showPassword1} className='eyes' src={closedEye} alt="" />
            </div>
            <p className="hidden"></p>
         </div>

         <div className="loginText">
            <a
               href="https://www.back.dance-riser.com/reset-password"
               target='_blank'
               rel='noreferrer'
            >
               Mot de passe oublié</a>
           
         </div>

         <input className='submitButton' type="submit" value="Connexion" />
         <div className='subscribe'>
            <Link to='/subscribe'>{"Vous n'avez pas de compte?"}<br/><span className='bold'>{"Inscrivez-vous !"}</span></Link>
            </div>
      </form>

</div>
 )
}
export default Login;