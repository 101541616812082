import React, { useEffect } from 'react';
import './MovesList.scss';
import ImageCard from '../ImageCard/ImageCard';
import { useDispatch, useSelector } from 'react-redux';
import { callMovesStylesList } from '../../actions/api';
import Loading from '../Loading/Loading';
import { setAllLoadingFalse } from '../../actions/action';


function MovesList() {
  const dispatch = useDispatch();
  const stylesList = useSelector((state) => state.reducer.moveStyleList)
  const loading = useSelector((state) => state.reducer.loading.loadingMovesStyleList)

  useEffect(() => {
    dispatch(setAllLoadingFalse())
    setTimeout(() => {
      dispatch(callMovesStylesList())
    }, 100);
  }, [])

  
  return(
  <div className='MovesList'>
    {loading && <Loading/>}
    <h2>{"Moves Styles :"}</h2>

      <section className='styleList'>
        {!loading && stylesList.map((style) => (
              <ImageCard
                key={style.name}
                title={style.name}
                lien={style.image}
                link={'/moves/styles/'+style.slug}
              />
        ))}
      </section>
  </div>
 )
}
export default MovesList;