import React, { useEffect } from 'react';
import './Moves.scss';
import { useDispatch, useSelector } from 'react-redux'   
import ImageCard from '../../../ImageCard/ImageCard';
import { callMovesList } from '../../../../actions/api';
import Loading from '../../../Loading/Loading';
import {
    // ChangeMovesLevelSearch,
    // setMovesFilter
    changeMovesSearchInput,
    movesFiltres,
    setAllLoadingFalse,
    setMoveInputSessionStorage,
} from '../../../../actions/action';
import { useParams } from 'react-router-dom';


function Moves() {

    const dispatch = useDispatch();
    const movesList = useSelector((state) => state.reducer.movesListPageFilter)
    const loading = useSelector((state) => state.reducer.loading.loadingMovesCategoriesPage)
    const user = useSelector((state) => state.reducer.user)
    const { slugMoves } = useParams();

    useEffect(() => {
        if (!loading) {
           if (sessionStorage.getItem('moveSearch')) {
              const myInputsNotparse = sessionStorage.getItem('moveSearch')
              const myInput = JSON.parse(myInputsNotparse);
                dispatch(setMoveInputSessionStorage(myInput.name))
              if (myInput.name) {
                 document.getElementById('name').value = myInput.name;
              }
           }  
        } 
    },[loading])

    useEffect(() => {
        dispatch(setAllLoadingFalse())
        setTimeout(() => {
            dispatch(callMovesList(slugMoves))
        }, 100);
    }, [])

    useEffect(() => {
        if (movesList === undefined) {
        window.location.href = '/error404'
        }
    }, [movesList])

    const handleChange = (e) => {
        dispatch(changeMovesSearchInput(e.target.name, e.target.value));
        dispatch(movesFiltres([]));
    }
 
  return(
    <div className='Moves'>
    {loading && <Loading/>}
        <h2>{"Moves :"}</h2>
       <section className='styleList'>
          

          <form >
              <div className="inputLabel">
                  <label htmlFor="name">Rechercher un mouvement</label>
                  <input id='name' onChange={handleChange} type="text" name='name' />
                  <p>( vous pouvez voir que les mouvements inférieur ou égale à votre niveau )</p>
                  <p>Votre niveau : {user.level}</p>
              </div>

              {/* <p
              className={`filterText ${!moveFilter ? '' : 'open'}`}
              onClick={handleClick}
              >
                  Filtres
                  <i className="fa-solid fa-caret-right"></i>
              </p>

              {moveFilter && (
                  <div className="inputLabel">
                      <p>{"Niveau de la chorégraphie"}</p>
                      <div className="inputCheckbox">
                          <input onChange={handleChangeLevel} type="checkbox" name="beginner"/>
                          <label htmlFor="beginner">Débutant</label>
                      </div>
                      <div className="inputCheckbox">
                          <input onChange={handleChangeLevel} type="checkbox" name="intermediate"/>
                          <label htmlFor="intermediate">Intermédiaire</label>
                      </div>
                      <div className="inputCheckbox">
                          <input onChange={handleChangeLevel} type="checkbox" name="advanced"/>
                          <label htmlFor="advanced">Avancé</label>
                      </div>
                      <div className="inputCheckbox">
                          <input onChange={handleChangeLevel} type="checkbox" name="professionnal"/>
                          <label htmlFor="professionnal">Professionnel</label>
                      </div>
                  </div>
              )} */}
          </form>
          </section>

        <section className='styleList cardList'>
            

            {!loading && movesList.map((move) => 
                user.level === 'Débutant' &&
                (
                    move.level.name === 'Débutant'
                ) &&
                (
                    <ImageCard
                        key={move.name}
                        title={move.name}
                        lien={move.image}
                        link={'/moves/styles/categories/moves/'+move.slug+'/'+move.id}
                        description={'niveau du mouvement: '+move.level.name}
                    />
                )
            )}

            {!loading && movesList.map((move) => 
                user.level === 'Intermédiaire' &&
                (
                    move.level.name === 'Intermédiaire' ||
                    move.level.name === 'Débutant'
                ) &&
                (
                    <ImageCard
                        key={move.name}
                        title={move.name}
                        lien={move.image}
                        link={'/moves/styles/categories/moves/'+move.slug+'/'+move.id}
                        description={'niveau du mouvement: '+move.level.name}
                    />
                )
            )}

            {!loading && movesList.map((move) => 
                user.level === 'Pro' &&
                (
                    move.level.name === 'Pro' ||
                    move.level.name === 'Intermédiaire' ||
                    move.level.name === 'Débutant'
                ) &&
                (
                    <ImageCard
                        key={move.name}
                        title={move.name}
                        lien={move.image}
                        link={'/moves/styles/categories/moves/'+move.slug+'/'+move.id}
                        description={'niveau du mouvement: '+move.level.name}
                    />
                )
            )}

            {!loading && movesList.map((move) => 
                user.level === 'Avancé' &&
                (
                    move.level.name === 'Avancé' ||
                    move.level.name === 'Pro' ||
                    move.level.name === 'Intermédiaire' ||
                    move.level.name === 'Débutant'
                ) &&
                (
                    <ImageCard
                        key={move.name}
                        title={move.name}
                        lien={move.image}
                        link={'/moves/styles/categories/moves/'+move.slug+'/'+move.id}
                        description={'niveau du mouvement: '+move.level.name}
                    />
                )
            )}


                
      </section>
    </div>
 )
}
export default Moves;