import React from 'react';
import './ProfilLink.scss';
import { Link } from 'react-router-dom';
import PropTypesLib from 'prop-types';

function ProfilLink({ link, name, img, handleClick }) {


  return(
    <div className='ProfilLink'>
       <Link
       to={"/"+link}
       onClick={handleClick}
       >
           {img && (
             <img className='imgPdp' src={img} />
           )}
           <span>{name}</span> 
           <i className="fa-solid fa-caret-right"></i>
       </Link>
    </div>
 )
}

ProfilLink.propTypes = {
    link: PropTypesLib.string.isRequired,
    name: PropTypesLib.string.isRequired,
    img: PropTypesLib.string,
    handleClick: PropTypesLib.func,
};


export default ProfilLink;