import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import './Profil.scss';
import ProfilLink from './ProfilLink/ProfilLink'
import { useDispatch } from 'react-redux'
import { logout } from '../../actions/action'

function Profil() {
   const dispatch = useDispatch();

   useEffect(() => {
      if (!isLogged) {
         window.location.href = '/login'
      }
   }, [])

   const { schoolList, avatar, isLogged } = useSelector((state) => state.reducer.user)
   const user = useSelector((state) => state.reducer.user)
   const handleClick = () => {
      dispatch(logout());
   }

  return(
    <div className='Profil'>
         <h2>Mon tableau de bord</h2>
         <ProfilLink img={avatar} link='user/settings' name='Détail profil' />
         <ProfilLink link='events' name='Voir les évènements' />
         <ProfilLink link='moves/styles' name='Voir les mouvements' />
         {user.role !== 'ROLE_ADMIN' && (
            <ProfilLink link='agenda' name='Voir mon agenda' />
         )}
         { user.schoolList[0] && (
            <ProfilLink link='choreography/styles' name='Voir les chorégraphies' />
         )}
         {schoolList != undefined &&
         
            schoolList.map((school) => (
               <ProfilLink key={school.school.slug} link={'schools/'+school.school.slug+'/'+school.school.id} name={'Voir '+school.school.name} />
               ))
            }
         <ProfilLink handleClick={handleClick} link='login' name='Se déconnecter' />
    </div>
 )
}
export default Profil;