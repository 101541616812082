import React, { useEffect } from 'react';
import './MovesCategories.scss';
import ImageCard from '../../ImageCard/ImageCard';
import { useDispatch, useSelector } from 'react-redux';
import { callMovesCategoriesList } from '../../../actions/api';
import Loading from '../../Loading/Loading';
import { useParams } from 'react-router-dom';
import { setAllLoadingFalse } from '../../../actions/action';


function MovesCategories() {
  const dispatch = useDispatch();
  const categoriesList = useSelector((state) => state.reducer.moveStylePage)
  const loading = useSelector((state) => state.reducer.loading.loadingMovesStylePage)
  const { slugCategories } = useParams();
  

  useEffect(() => {
    dispatch(setAllLoadingFalse())
    setTimeout(() => {
      dispatch(callMovesCategoriesList(slugCategories))
    }, 100);
  }, [])

  useEffect(() => {
    if (categoriesList === undefined) {
       window.location.href = '/error404'
    }
 }, [categoriesList])

  return(
    <div className='MovesCategories'>
  {loading && <Loading/>}
        <h2>{"Moves Categories :"}</h2>

       <section className='styleList'>
       
       {!loading && categoriesList.map((category) => (
            <ImageCard
              key={category.name}
              title={category.name}
              lien={category.image}
              link={'/moves/styles/categories/'+category.slug}
            />
         ))}

      </section>
    </div>
 )
}
export default MovesCategories;