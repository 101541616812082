import React from 'react';
import PropTypesLib from 'prop-types';

import './VideoCard.scss';


// Composant servant a afficher une video dans 
function VideoCard({ description, link }) {
  return(
      <div className='VideoCard'>

                  <iframe
                        width={"560"}
                        height={"500"}
                        src={link}
                        frameBorder={"0"}
                        allow={"accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"}
                        loading={"lazy"}
                        allowFullScreen
                  />
                  <p>{description}</p>     
      </div>
 )
}

VideoCard.propTypes = {
   description: PropTypesLib.string.isRequired,
   link: PropTypesLib.string.isRequired,
};


export default VideoCard;