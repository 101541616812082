import React from 'react';
import PropTypesLib from 'prop-types';

import './ImageCard.scss';
import { Link } from 'react-router-dom';


function ImageCard({ title, date, description, lien, link, city }) {


  return(
      <div className='ImageCard'>
            <Link to={link}>
                  <img src={lien} loading="lazy" alt="lieu de l'évènement" />
                  <h1>{title} <span>{date ? date.substr(0, 13) : ''}</span></h1>
                  <p>{description}</p>
                  <p>{city ? 'ville: '+city : ''}</p>
            </Link>
      </div>
 )
}

ImageCard.propTypes = {
   title: PropTypesLib.string.isRequired,
   date: PropTypesLib.string,
   description: PropTypesLib.string,
   lien: PropTypesLib.string,
   link: PropTypesLib.string,
   city: PropTypesLib.string,
};


export default ImageCard;