import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { changeInput, changePDP } from '../../actions/action';
import { sendSubscribeForm } from '../../actions/api';
import closedEye from '../../assets/Images/yeux.png'
import openedEye from '../../assets/Images/oeil.png'
import './Subscribe.scss';


function Subscribe() {

  const dispatch = useDispatch();
  const user = useSelector((state) => state.reducer.user)
  
  const handleSubmit = (e) => {
    e.preventDefault()
    if (user.confirmPass === user.password) {
      dispatch(sendSubscribeForm(
        user.email,
        user.password,
        user.blaze,
        user.firstName,
        user.lastName,
        user.avatar,
      ));
    }
  }

  
  const showPassword1 = () => {
      const passwordElement = document.querySelector("#show1");
      const eyeElement = document.querySelector("#eye1");
      if (passwordElement.type === "password") {
        eyeElement.src = openedEye
        passwordElement.type = "text";
      } else {
        eyeElement.src = closedEye
        passwordElement.type = "password";
      }
    } 

    const showPassword2 = () => {
      const passwordElement = document.querySelector("#show2");
      const eyeElement = document.querySelector("#eye2");
      if (passwordElement.type === "password") {
        eyeElement.src = openedEye
        passwordElement.type = "text";
      } else {
        eyeElement.src = closedEye
        passwordElement.type = "password";
      }
    } 

  

  const handleChange = (e) => {
    const wrongMDPElement = document.querySelector("form > div:nth-child(8) > p")
    if (e.target.name === 'confirmPass') {
      if (e.target.value != user.password) {
        wrongMDPElement.className = 'warning'
        wrongMDPElement.textContent = "Le mot de passe ne correspont pas."
        return ;
      }
    }
    wrongMDPElement.className='hidden'
    dispatch(changeInput(e.target.name, e.target.value));
  }

  
  
  const fileChange = (e) => {
    let file = e.target.files[0]
    const warningElement = document.querySelector("div.inputLabel.file > p");
    if(file.size > 1048576 ){
      const warning = document.querySelector('.warning');
      if (warning){
        return;
      }
      warningElement.className = 'warning';
      warningElement.textContent = "La taille de votre image est trop grande, celle-ci n'a pas été ajoutée, veuillez sélectionner une autre image. Taille maximum = 1Mo";
      
      return ;
    }
    warningElement.className='hidden';
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.addEventListener('load', () => {
      dispatch(changePDP(reader.result));
    });
  };


  return(
    <div className='Subscribe'>

       <form onSubmit={handleSubmit}>
          <h2>Se créer un compte</h2>

          <div className="inputLabel file">
            <img className='imgPdp' src={user.avatar} alt="pdp" />
            <label htmlFor="pdp">Avatar</label>
            <p>{"(vous pourrez choisir, ou modifier votre avatar dans votre profil. Taille image max = 1Mo)"}</p>
            <p className='hidden'></p>
            <input className='pdp' onChange={fileChange} type="file" name='pdp' accept=".jpeg, .jpg, .PNG, .png, .svg, image/*"/>
          </div>

          <div className="inputLabel">
            <label htmlFor="lastName">Nom*</label>
            <input onChange={handleChange} type="text" name='lastName'  required />
          </div>

          <div className="inputLabel">
            <label htmlFor="firstName">Prénom*</label>
            <input onChange={handleChange} type="text" name='firstName' required/>
          </div>

          <div className="inputLabel">
            <label htmlFor="blaze">Blaze</label>
            <input onChange={handleChange} type="text" name='blaze' />
          </div>

          <div className="inputLabel">
            <label htmlFor="email">Email*</label>
            <input onChange={handleChange} type="email" name='email' required/>
          </div>

          <div className="inputLabel checkValidation">
            <label htmlFor="password">Mot de passe*</label>
            <p>{"Votre mot de passe doit contenir au moins: une majuscule, une minuscule, un chiffre, et un caractère spécial (longueur comprise entre 8 et 32 caractères)."}</p>
            <div className='passwordWraper'>
            <input id='show1' className='password' onChange={handleChange} type="password" name='password' pattern="^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*?_=+\-]).{8,32}$" autoComplete={"new-password"}  required/>
            <img id='eye1' onClick={showPassword1} className='eyes' src={closedEye} alt="" />
            </div>
          </div>

          <div className="inputLabel checkValidation">
            <label htmlFor="confirmPass">Confirmer le mot de passe*</label>
            <div className='passwordWraper'>
            <input id='show2' className='password' onChange={handleChange} type="password" name='confirmPass' pattern="^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*?_=+\-]).{8,32}$" required/>
            <img id='eye2' onClick={showPassword2} className='eyes' src={closedEye} alt="" />
            </div>
            <p className='hidden'></p>
          </div>
          <p>{"(* Champs obligatoires)"}</p>
          <input className='submitButton' type="submit" value="Inscription" />
       </form>

    </div>
 )
}
export default Subscribe;