/* eslint-disable no-undef */
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { 
   setEventFilter,
   changeEventsSearchInput,
   // returnEventsFiltered,
   eventSearchSubmit,
   eventResetSubmit,
   setEventInputSessionStorage,
   setAllLoadingFalse,
} from '../../actions/action';
import ImageCard from '../ImageCard/ImageCard';
import './Events.scss';
import { callEvents, } from '../../actions/api';
import Loading from '../Loading/Loading';

function Events() {
   const dispatch = useDispatch();
   const eventFilter = useSelector((state) => state.reducer.webSite.eventFilter);
   const eventListFilter = useSelector((state) => state.reducer.eventListFilter);
   const loading = useSelector((state) => state.reducer.loading.loadingEvents);
   

   useEffect(() => {
      dispatch(setAllLoadingFalse())
      dispatch(callEvents());

      setTimeout(() => {
         if (eventListFilter === undefined) {
            window.location.href = '/login'
         }
      }, 100);

   }, []);

   useEffect(() => {
   }, [eventListFilter])

   useEffect(() => {
      if (!loading) {
         if (sessionStorage.getItem('eventSearch')) {
            const myInputsNotparse = sessionStorage.getItem('eventSearch')
            const myInput = JSON.parse(myInputsNotparse);
            dispatch(setEventInputSessionStorage(myInput.name, myInput.postalCode, myInput.city));
            if (myInput.name) {
               document.getElementById('name').value = myInput.name;
            }
         }  
      } 
   },[loading])

   useEffect(() => {
      if (!loading) {
         if (sessionStorage.getItem('eventSearch')) {
            const myInputsNotparse = sessionStorage.getItem('eventSearch')
            const myInput = JSON.parse(myInputsNotparse);
            if (myInput.city) {
               document.getElementById('city').value = myInput.city;
            }
            if (myInput.city) {
               document.getElementById('postalCode').value = myInput.postalCode;
            }
         }  
      } 
   }, [eventFilter])

   const handleClick = () => {
      dispatch(setEventFilter({}));
   };

   const handleChange = (e) => {
      dispatch(changeEventsSearchInput(e.target.name, e.target.value));
      // dispatch(returnEventsFiltered(eventList, e.target.name));
   };

   const handleSubmitSearch = (e) => {
      e.preventDefault()
      dispatch(eventSearchSubmit())
   };
   const handleSubmitReset = (e) => {
      // reset values in reducer
      dispatch(eventResetSubmit())
      // reset values in inputs
      const form = e.target.parentElement.parentElement;
      const name = form.children[0].children[1];
      const postalCode = form.children[2].children[0].children[1];
      const city = form.children[2].children[1].children[1];
      name.value = '';
      postalCode.value = '';
      city.value = '';
      // reset values in inputs
   };

  return(
   <div className='Events'>
   
      {loading && <Loading />}
      {!loading && ( 
      <>
      
      <h2>Nos évènements</h2>
         
      <form >
         <div className="inputLabel">
            <label htmlFor="name">Rechercher un évènement</label>
            <input id='name' onChange={handleChange} type="text" name='name' />
         </div>


         <p
         className={`filterText ${!eventFilter ? '' : 'open'}`}
         onClick={handleClick}
         >
            Filtres
            <i className="fa-solid fa-caret-right"></i>
         </p>

         {eventFilter && (
            <div className="filters">
               <div className="inputLabel">
                  <label htmlFor="postalCode">Code postal</label>
                  <input id='postalCode' onChange={handleChange} type="number" name='postalCode' placeholder='83' />
               </div>

               <div className="inputLabel">
                  <label htmlFor="city">Ville</label>
                  <input id='city' onChange={handleChange} type="text" name='city' />
               </div>

               <input onClick={handleSubmitReset} type="button" value="réinitialiser les filtres" />
            </div>
         )}
         <input className='searchButton' onClick={handleSubmitSearch} type="button" value="rechercher" />

      </form>

      <section className="eventList">
         {eventListFilter.events.map((event) => (
            <ImageCard
               key={event.slug}
               title={event.name}
               description={event.description}
               date={"le "+ eventListFilter.dates[event.id]}
               lien={event.image}
               link={'/events/'+event.slug+'/'+event.id}
            />
         ))}
      </section>
      </>
      )}
   </div>
 )
}
export default Events;