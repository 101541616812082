import React from 'react';
import './ValidationPage.scss';
import { Link } from 'react-router-dom';

function ValidationPage() {


  return(
    <div className='ValidationPage'>
       <h3>{"Votre demande a bien été prise en compte."}</h3>
       <p>{"Nous vous remercions d'utiliser les services Dance Riser."}</p>
       <Link to='/'>
       <button>{"HOME"}</button>
       </Link>
    </div>
 )
}
export default ValidationPage;