import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { changeFormInput, setAllLoadingFalse, submitContactForm } from '../../actions/action';
import './Contact.scss';


function Contact() {

    const dispatch = useDispatch();
    const contact = useSelector((state) => state.reducer.contact);

    useEffect(() => {
        dispatch(setAllLoadingFalse())
    }, []);
  
    const handleSubmit = (e) => {
        e.preventDefault();
        dispatch(submitContactForm());
    }

    const handleChange = (e) => {
        dispatch(changeFormInput(e.target.name, e.target.value));
    }

    useEffect(() => {
        if (contact.submit === true) {
            setTimeout(() => {
                window.location.href = '/'
            }, 3000)
        }
    }, [contact.submit])


if (contact.submit === false) {
    return(
        <div className='Contact'>
  
            <form onSubmit={handleSubmit}>
            <h2>Nous contacter</h2>
    
            <div className="inputLabel">
                <label htmlFor="email">Email*</label>
                <input onChange={handleChange} type="email" name='email' required/>
            </div>
    
            <div className="inputLabel">
                <label htmlFor="firstLastName">Nom et prénom*</label>
                <input onChange={handleChange} type="text" name='firstLastName' required/>
            </div>
    
            <div className="inputLabel">
                <label htmlFor="subject">Sujet*</label>
                <input onChange={handleChange} type="text" name='subject' required/>
            </div>
    
            <div className="inputLabel">
                <label htmlFor="content">Message*</label>
                <textarea onChange={handleChange} name="content" required/>
            </div>
    
            <p>{"(* Champs obligatoires)"}</p>
            <input className='submitButton' type="submit" value="Envoyer" />
            </form>
  
        </div>
   ) 
}else{
    return(
        <div className='Contact Submit'>
    
           <h1 className='thanksMsg'>{`Merci pour ton message Idris bnd ${contact.firstLastName} :)`}</h1>
           <p className='redirectionMsg'>tu vas être redirigé vers la home page dans 3 secondes</p>
    
        </div>
     ) 
}

}
export default Contact;