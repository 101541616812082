import React, { useEffect } from 'react';
import ImageCard from '../../ImageCard/ImageCard';
import './ChoreographyList.scss';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  // ChangeChoreLevelSearch,
  // setChoreoFilter,
  changeChoreoSearchInput,
  choreoFiltres,
  setAllLoadingFalse,
  setChoreInputSessionStorage,
} from '../../../actions/action';
import { callChoreographysList } from '../../../actions/api';
import Loading from '../../Loading/Loading';

function ChoreographyList() {

  const dispatch = useDispatch();
  const { slugStyle } = useParams();
 
  // const choreoFilter = useSelector((state) => state.reducer.webSite.choreographyFilter);
  // const handleClick = () => {
  //   dispatch(setChoreoFilter())
  // };
  // const handleChangeLevel = (e) => {
  //   dispatch(ChangeChoreLevelSearch(e.target.name, e.target.checked));
  // };
  const loading = useSelector((state) => state.reducer.loading.loadingChoreStylePage);
  const choreList = useSelector((state) => state.reducer.choreStylePageFilter);
  
  
  useEffect(() => {
    dispatch(setAllLoadingFalse())
  }, []);

  useEffect(() => {
    if (!loading) {
       if (sessionStorage.getItem('choreSearch')) {
          const myInputsNotparse = sessionStorage.getItem('choreSearch')
          const myInput = JSON.parse(myInputsNotparse);
          dispatch(setChoreInputSessionStorage(myInput.name))
          if (myInput.name) {
             document.getElementById('name').value = myInput.name;
          }
       }  
    } 
 },[loading])


  useEffect(() => {
    setTimeout(() => {
      if(localStorage.getItem("token")) {
        dispatch(callChoreographysList(slugStyle))
      }else{
        window.location.href = '/login';
      }
  }, 100);
  }, [])

  const handleChange = (e) => {
    dispatch(changeChoreoSearchInput(e.target.name, e.target.value))
    dispatch(choreoFiltres([]))
  };

  return(
    <div className='ChoreographyList'>
    {loading && <Loading/>}
    {!loading && 
    <>
      <section className='styleList'>
        <h2>{"Choreographies :"}</h2>

        <form >
            <div className="inputLabel">
                <label htmlFor="name">Rechercher une chorégraphie</label>
                <input id='name' onChange={handleChange} type="text" name='name' />
            </div>

            {/* <p
            className={`filterText ${!choreoFilter ? '' : 'open'}`}
            onClick={handleClick}
            >
                Filtres
                <i className="fa-solid fa-caret-right"></i>
            </p>

            {choreoFilter && (
                <div className="inputLabel">
                    <p>{"Niveau de la chorégraphie"}</p>
                    <div className="inputCheckbox">
                        <input onChange={handleChangeLevel} type="checkbox" name="beginner"/>
                        <label htmlFor="beginner">Débutant</label>
                    </div>
                    <div className="inputCheckbox">
                        <input onChange={handleChangeLevel} type="checkbox" name="intermediate"/>
                        <label htmlFor="intermediate">Intermédiaire</label>
                    </div>
                    <div className="inputCheckbox">
                        <input onChange={handleChangeLevel} type="checkbox" name="advanced"/>
                        <label htmlFor="advanced">Avancé</label>
                    </div>
                    <div className="inputCheckbox">
                        <input onChange={handleChangeLevel} type="checkbox" name="professionnal"/>
                        <label htmlFor="professionnal">Professionnel</label>
                    </div>
                </div>
            )} */}

        </form>
      </section>

      <section className='styleList'>

        {choreList.map((chore) => (
          <>
            <h3 key={chore.school.name}>école: {chore.school.name}</h3>
            <section className='cardList styleList'>
            <ImageCard
              key={chore.name}
              title={chore.name}
              description={chore.description}
              lien={chore.image}
              link={`/choreography/styles/${slugStyle}/${chore.slug}/${chore.id}`}
            />
            </section>
          </>
        ))}

      </section>
      </>
    }
    </div>
 )
}
export default ChoreographyList;