import React from 'react';
import { useSelector } from 'react-redux';
import PropTypesLib from 'prop-types';
import { NavLink } from 'react-router-dom';
import './NavMobile.scss';

function NavMobile({ closeNavFunction }) {

    const navOpen = useSelector((state) => state.reducer.webSite.navOpen)
    const user = useSelector((state) => state.reducer.user)

    const routeArray = {
        anonymous:[
            {
                name:'Home',
                to:'/',
            },
            {
                name:'Nos écoles',
                to:'/schools',
            },
            {
                name:'Les évènements',
                to:'/events',
            },
            {
                name:'Contact',
                to:'/contact',
            },
        ],
    }



    switch (user.role) {
        case 'anonymous':
            return(
                <div className={navOpen ? 'NavMobile open' : 'NavMobile'}>
                    {routeArray.anonymous.map((link) => (
                        <NavLink
                            key={link.name}
                            to={link.to}
                            className='navlink'
                            onClick={closeNavFunction}
                        >
                            {link.name}
                        </NavLink>
                    ))}
                </div>
            );
        case 'ROLE_MEMBER':
            return(
                <div className={navOpen ? 'NavMobile open' : 'NavMobile'}>
                    {routeArray.anonymous.map((link) => (
                        <NavLink
                            key={link.name}
                            to={link.to}
                            className='navlink'
                            onClick={closeNavFunction}
                        >
                            {link.name}
                        </NavLink>
                    ))}
                        <div className="navLine"></div>
                        <NavLink
                            to={'/user'}
                            className='navlink'
                            onClick={closeNavFunction}
                        >
                            {'Mon profil'}
                        </NavLink>
                        <NavLink
                            to={'/moves/styles'}
                            className='navlink'
                            onClick={closeNavFunction}
                        >
                            {'Mouvements'}
                        </NavLink>

                        { user.schoolList[0] && (
                            <div className='ChoreSchool'>
                                <NavLink
                                    to={'/choreography/styles'}
                                    className='navlink'
                                    onClick={closeNavFunction}
                                >
                                    {'Chorégraphies'}
                                </NavLink>
                            </div>
                        )}
                                <NavLink
                                    to={'/agenda'}
                                    className='navlink'
                                    onClick={closeNavFunction}
                                >
                                    {'Agendas'}
                                </NavLink>
                </div>
            );
        case 'ROLE_MANAGER':
                return(
                    <div className={navOpen ? 'NavMobile open' : 'NavMobile'}>
                        {routeArray.anonymous.map((link) => (
                            <NavLink
                                key={link.name}
                                to={link.to}
                                className='navlink'
                                onClick={closeNavFunction}
                            >
                                {link.name}
                            </NavLink>
                        ))}
                            <div className="navLine"></div>

                            <NavLink
                            to={'/user'}
                            className='navlink'
                            onClick={closeNavFunction}
                            >
                                {'Mon profil'}
                            </NavLink>
                            <NavLink
                                to={'/moves/styles'}
                                className='navlink'
                                onClick={closeNavFunction}
                            >
                                {'Mouvements'}
                            </NavLink>
                            <NavLink
                                to={'/choreography/styles'}
                                className='navlink'
                                onClick={closeNavFunction}
                            >
                                {'Chorégraphies'}
                            </NavLink>
                            <NavLink
                                    to={'/agenda'}
                                    className='navlink'
                                    onClick={closeNavFunction}
                                >
                                    {'Agendas'}
                                </NavLink>
                                <div className="navLine"></div>
                            <a href="https://www.back.dance-riser.com/"
                                target='_blank'
                                rel='noreferrer'
                                className='navlink'
                                onClick={closeNavFunction}
                            >
                                Gestion
                            </a>
                    </div>
                );
        case 'ROLE_ADMIN':
                    return(
                        <div className={navOpen ? 'NavMobile open' : 'NavMobile'}>
                            {routeArray.anonymous.map((link) => (
                                <NavLink
                                    key={link.name}
                                    to={link.to}
                                    className='navlink'
                                    onClick={closeNavFunction}
                                >
                                    {link.name}
                                </NavLink>
                            ))}
                                <div className="navLine"></div>

                                <NavLink
                                to={'/user'}
                                className='navlink'
                                onClick={closeNavFunction}
                                >
                                    {'Mon profil'}
                                </NavLink>
                                <NavLink
                                    to={'/moves/styles'}
                                    className='navlink'
                                    onClick={closeNavFunction}
                                >
                                    {'Mouvements'}
                                </NavLink>
                                <NavLink
                                    to={'/choreography/styles'}
                                    className='navlink'
                                    onClick={closeNavFunction}
                                >
                                    {'Chorégraphies'}
                                </NavLink>
                                 <div className="navLine"></div>

                                <a href="https://www.back.dance-riser.com/"
                                    target='_blank'
                                    rel='noreferrer'
                                    className='navlink'
                                    onClick={closeNavFunction}
                                >
                                    Gestion
                                </a>
                        </div>
                    );
    
        default:
            return(
                <div className={navOpen ? 'NavMobile open' : 'NavMobile'}>
                    {routeArray.anonymous.map((link) => (
                        <NavLink
                            key={link.name}
                            to={link.to}
                            className='navlink'
                            onClick={closeNavFunction}
                        >
                            {link.name}
                        </NavLink>
                    ))}
                </div>
            );
    }



}


NavMobile.propTypes = {
    closeNavFunction: PropTypesLib.func.isRequired
};


export default NavMobile;