import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
   setSchoolFilter,
   changeSchoolSearchInput,
   schoolSearchSubmit,
   schoolResetSubmit,
   setSchoolInputSessionStorage,
   setAllLoadingFalse,
} from '../../actions/action';
import { callSchools } from '../../actions/api';
import ImageCard from '../ImageCard/ImageCard';
import Loading from '../Loading/Loading';
import './Schools.scss';


function Schools() {
   const dispatch = useDispatch();
   const schoolFilter = useSelector((state) => state.reducer.webSite.schoolFilter);
   const schoolList = useSelector((state) => state.reducer.schoolList);
   const loading = useSelector((state) => state.reducer.loading.loadingSchools);
   const schoolListFilter = useSelector((state) => state.reducer.schoolListFilter);

   useEffect(() => {
      dispatch(setAllLoadingFalse())
      dispatch(callSchools())
   }, [])

   useEffect(() => {
      if (!loading) {
         if (sessionStorage.getItem('schoolSearch')) {
            const myInputsNotparse = sessionStorage.getItem('schoolSearch')
            const myInput = JSON.parse(myInputsNotparse);
            dispatch(setSchoolInputSessionStorage(myInput.name, myInput.postalCode, myInput.city))
            if (myInput.name) {
               document.getElementById('name').value = myInput.name;
            }
         }  
      } 
   },[loading])

   useEffect(() => {
      if (!loading) {
         if (sessionStorage.getItem('schoolSearch')) {
            const myInputsNotparse = sessionStorage.getItem('schoolSearch')
            const myInput = JSON.parse(myInputsNotparse);
            if (schoolFilter) {
               if (myInput.city) {
                  document.getElementById('city').value = myInput.city;
               }
               if (myInput.city) {
                  document.getElementById('postalCode').value = myInput.postalCode;
               }
            }
         }  
      } 
   }, [schoolFilter])

   
   useEffect(() => {
      setTimeout(() => {
         if (schoolList === undefined) {
            window.location.href = '/error404';
         }
      }, 100)

   }, [schoolList])
   
   const handleClick = () => {
      dispatch(setSchoolFilter())
   };

   const handleSubmitSearch = (e) => {
      e.preventDefault()
      dispatch(schoolSearchSubmit())
   };

   const handleChangeInput = (e) => {
      dispatch(changeSchoolSearchInput(e.target.name, e.target.value))
   };

   const handleSubmitReset = (e) => {
      dispatch(schoolResetSubmit())
      // reset values in inputs
      const form = e.target.parentElement.parentElement;
      const name = form.children[0].children[1];
      const postalCode = form.children[2].children[0].children[1];
      const city = form.children[2].children[1].children[1];
      name.value = '';
      postalCode.value = '';
      city.value = '';
      // reset values in inputs
   }

   if (loading) {
      return(
         <Loading/>
      )
   }else{   
  return(
   <div className='Schools'>
      <section className="eventList">
      <h2>Nos écoles</h2>  
      <form >
         <div className="inputLabel">
            <label htmlFor="name">Rechercher ton école</label>
            <input id='name' onChange={handleChangeInput} type="text" name='name' />
         </div>

         <p
            className={`filterText ${!schoolFilter ? '' : 'open'}`}
            onClick={handleClick}
         >
            Filtres
            <i className="fa-solid fa-caret-right"></i>
         </p>

         {schoolFilter && (
            <div className="filters">
               <div className="inputLabel">
                  <label htmlFor="postalCode">Code postal</label>
                  <input id='postalCode' onChange={handleChangeInput} type="text" name='postalCode' placeholder='83' />
               </div>

               <div className="inputLabel">
                  <label htmlFor="city">Ville</label>
                  <input id='city' onChange={handleChangeInput} type="text" name='city' />
               </div>

               <input onClick={handleSubmitReset} type="button" value="réinitialiser les filtres" />
            </div>
         )}
         <input className='searchButton' onClick={handleSubmitSearch} type="button" value="rechercher" />

      </form>
      </section>

      <section className="eventList cards">
         {schoolListFilter.map((school) => (
            <ImageCard
               key={school.name}
               title={school.name}
               description={school.description}
               lien={school.image}
               link={'/schools/'+school.slug+'/'+school.id}
               city={school.location}
            />
         ))}
      </section>
   </div>
 )
}
}
export default Schools;