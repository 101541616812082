import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import './ProfilDetails.scss';
import {
   changeUserSettingsInput,
   modifySettingsProfile,
   changePDPSettings,
   closeSettingsProfile,
} from '../../../actions/action';
import {
   sendDetailProfil,
} from '../../../actions/api';

import ProfilLink from '../ProfilLink/ProfilLink';


function ProfilDetails() {
   const user = useSelector((state) => state.reducer.user)
   const change = useSelector((state) => state.reducer.webSite.detailsProfilChange)
   const userInputs = useSelector((state) => state.reducer.webSite.detailsProfilChangeInputs)
   const dispatch = useDispatch();
   useEffect(() => {
      if (!user.isLogged) {
       window.location.href = '/login'
      }else{
         if (!userInputs.firstName) {
            window.location.href = '/user'  
         }  
      }
   }, [])

   const inputArray = [
      {
         name:'Nom',
         inputFor:'lastName',
         type:'text',
         value: userInputs.lastName,
         required: true,

      },
      {
         name:'Prénom',
         inputFor:'firstName',
         type:'text',
         value: userInputs.firstName,
         required: true,

      },
      {
         name:'Blaze',
         inputFor:'blaze',
         type:'text',
         value: userInputs.blaze,
         required: false,

      },
      {
         name:'Email',
         inputFor:'email',
         type:'email',
         value: userInputs.email,
         required: true,

      },
      {
         name:"Lien de l'agenda",
         inputFor:'agendaLink',
         type:'text',
         value: userInputs.agendaLink,
         required: false,
      }
   ];

   const handleCloseSettings = () => {
      dispatch(closeSettingsProfile())
   };

   const handleModify = (e) => {
      e.preventDefault()
      dispatch(modifySettingsProfile()) 
   };

   const handleSubmit= (e) => {
      e.preventDefault()
      dispatch(sendDetailProfil(
         userInputs.email,
         userInputs.blaze,
         userInputs.firstName,
         userInputs.lastName,
         userInputs.avatar,
         userInputs.agendaLink,
      ))
   };
   
   const handleChange= (e) => {
      dispatch(changeUserSettingsInput(e.target.name, e.target.value));
   };

   const fileChange = (e) => {
      const file = e.target.files[0]
      const warningElement = document.querySelector("div.inputLabel.file > p");
      if(file.size > 1048576 ){
        const warning = document.querySelector('.warning')
        if (warning){
          return;
        }
        warningElement.className = 'warning'
        warningElement.textContent = "La taille de votre image est trop grande, celle-ci n'a pas été ajoutée, veuillez sélectionner une autre image. Taille maximum = 1Mo"
        return ;
      }
      warningElement.className='hidden'
      const reader = new FileReader();
      reader.readAsDataURL(file);
      
      reader.addEventListener('load', () => {
        dispatch(changePDPSettings(reader.result));
      });
   };

  return(
    <div className='ProfilDetails'>
       <form onSubmit={change ? handleSubmit : handleModify}>
          <h2>Mon profil</h2>

         {change && (
            <div className="inputLabel file">
               <img className='imgPdp' src={userInputs.avatar} alt="pdp" />
               <p className='hidden'></p>
               <input className='pdpInput' onChange={fileChange} type="file" name='pdp' />
            </div>
         )}
         {!change && (
            <div className="inputLabel file">
               <img className='imgPdp' src={user.avatar} alt="pdp" />
               <input className='pdpInput' disabled type="file" name='pdp' />
            </div>
         )}

          {inputArray.map((input) => (
            <div key={input.name} className="inputLabel">
               <label htmlFor={input.inputFor}>{input.name}</label>
               {change && (
                  <input onChange={handleChange} type={input.type} name={input.inputFor} value={input.value} required={input.required}/>
               )}
               {!change && (
                  <input onChange={handleChange} type={input.type} name={input.inputFor} disabled value={input.value}/>
               )}
            </div>
          ))
          }

         
         <input className='submitButton' type="submit" value={change ? "Enregister la modification" : "Modifier mon profil"} />
         {change && (
         <p onClick={handleCloseSettings} className='submitButton'>
            {"Annuler la modification"}
         </p>
         )}
         {!change && (
         <ProfilLink link='security/changepassword' name='Modifier son mot de passe' />
         )}
         </form>
    </div>
 )
}
export default ProfilDetails;